/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useUserDrawerStyles = createStyles((theme) => ({
  group: {
    alignItems: "center",
    gap: "30px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      alignItems: "center",
      gap: "15px",
    },
  },
  cell: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileRowTwo: {
    display: "flex",
    gap: "20px",
  },
  mobileRowOne: {
    display: "flex",
    gap: "20px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: "flex",
      alignItems: "center",
      gap: "70px",
    },
  },
  mobileGridWrapper: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: "-20px",
      width: "400px",
    },
  },
}));
