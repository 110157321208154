import React from "react";

import {
  Card as MantineCard,
  Image,
  Text,
  Button,
  Group,
  Title,
  Space,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import DateCountdownCompact from "../../DateCountdown/DateCountdownCompact";
import { useCardStyles } from "../styles";
import useCountdown from "../../../hooks/useCountdown";

function RaffleCard({
  startDate,
  endDate,
  image,
  title,
  entries,
  numberOfWinners,
  userTotal,
  id,
  _id,
}) {
  const { classes } = useCardStyles();
  const navigate = useNavigate();

  const [started, ended] = useCountdown(startDate, endDate);

  return (
    <div className={classes.wrapper}>
      <MantineCard shadow="sm" p="lg" className={classes.card} radius="xl">
        <div className={classes.imageWrapper}>
          <Image
            src={image.url}
            alt="Raffle Graphic"
            radius="sm"
            className={classes.image}
            width="355px"
            height="340px"
          />
        </div>

        <div className={classes.contentWrapper}>
          <Title className={classes.cardTitle} order={2}>
            {title}
          </Title>

          <Group className={classes.group}>
            <Text size="md"># Entries: {entries || 0}</Text>
            <Text size="md"># Winners: {numberOfWinners}</Text>
          </Group>
          <Space h="xs" />
          <div>
            <DateCountdownCompact
              datetime={!started ? startDate : endDate}
              started={started}
            />
          </div>

          <div className={classes.buttonWrapper}>
            {!ended ? (
              <Button
                variant="outline"
                className={classes.button}
                onClick={() => navigate(`/raffle/${id}?id=${id}`)}
              >
                {started ? "Enter Raffle" : "View Details"}
              </Button>
            ) : (
              <Button
                variant="outline"
                className={classes.button}
                onClick={() => navigate(`/winners/${id}?id=${_id}`)}
              >
                View Winners
              </Button>
            )}
          </div>
        </div>
      </MantineCard>
    </div>
  );
}

export default RaffleCard;
