import React, { useEffect, useState } from "react";
import { Modal, Button, Title, Container, Space } from "@mantine/core";
import { RichText } from "prismic-reactjs";
import api from "../../api/api";

function InfoModal({ opened, setOpened }) {
  const [data, setData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const result = await api
        .get({
          endpoint: "/prismic/info-modal",
        })
        .then((response) => response.data);
      setData(result);
    };
    getData();
  }, []);
  if (!data) return <></>;
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={1} transform="uppercase">
            {data.data.title}
          </Title>
        }
        size={600}
      >
        <Container>
          <RichText render={data.data.body} />
          <Space h="lg" />
          <Button onClick={() => setOpened(false)} style={{ width: "100%" }}>
            {data.data.button_label}
          </Button>
        </Container>
      </Modal>
    </>
  );
}
export default InfoModal;
