import React from "react";
import {
  Group,
  Title,
  Text,
  Divider,
  Container,
  Accordion,
  Grid,
  Space,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import getShortAddress from "../../../utils/shortAddress";
import { useUserDrawerStyles } from "../styles";

function UserRaffles({ rafflePurchases }) {
  return (
    <>
      {!rafflePurchases || rafflePurchases.length === 0 ? (
        <div>
          <Space h="md" />
          <Text>
            No raffle purchases yet! Test your luck and enter our latest raffle!
          </Text>
        </div>
      ) : (
        <Accordion initialItem={0} iconPosition="right">
          {rafflePurchases.map((raffle) => {
            return (
              <Accordion.Item
                key={raffle._id}
                label={<AccordionLabel raffle={raffle} />}
              >
                <Container px={20}>
                  {raffle.raffles.map((rafflePurchase) => {
                    return <GridItem rafflePurchase={rafflePurchase} />;
                  })}
                </Container>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </>
  );
}

function GridItem({ rafflePurchase }) {
  return (
    <div key={rafflePurchase._id} className="-ml-5">
      <Divider />
      <Space h="md" />
      <Grid gutter="xs">
        <Grid.Col xs={6} md={3}>
          <Text size="xs" color="gray">
            Claim Id
          </Text>
          <Text size="xs" weight={500}>
            {rafflePurchase._id}
          </Text>
        </Grid.Col>

        <Grid.Col xs={6} md={2}>
          <Text size="xs" color="gray">
            Ticket(s)
          </Text>
          <Text size="sm">{rafflePurchase.tickets}</Text>
        </Grid.Col>

        <Grid.Col xs={6} md={3}>
          <Text size="xs" color="gray">
            Purchase TXN
          </Text>
          <Text size="sm">
            <a
              href={`https://solscan.io/tx/${rafflePurchase.ticketTransactionId}`}
              target="_blank"
              rel="noreferrer"
            >
              {getShortAddress(rafflePurchase.ticketTransactionId)}{" "}
              <FontAwesomeIcon icon="link" />
            </a>
          </Text>
        </Grid.Col>

        <Grid.Col xs={6} md={2}>
          <Text size="xs" color="gray">
            Success?
          </Text>
          {rafflePurchase.ticketPurchaseSuccessful && (
            <FontAwesomeIcon icon="check-circle" color="green" />
          )}
          {!rafflePurchase.ticketPurchaseSuccessful && (
            <FontAwesomeIcon icon="times" color="red" />
          )}
        </Grid.Col>
        <Grid.Col xs={6} md={2}>
          <Text size="xs" color="gray">
            Date
          </Text>
          <Text size="sm">
            {new dayjs(rafflePurchase.createdAt).format("MM/DD h:mm a")}
          </Text>
        </Grid.Col>
      </Grid>
      <Space h="sm" />
    </div>
  );
}

function AccordionLabel({ raffle }) {
  const { classes } = useUserDrawerStyles();
  return (
    <Group
      position="left"
      align="flex-start"
      spacing="lg"
      className={classes.group}
    >
      <Title order={6}>{raffle.raffle.title}</Title>

      <div className={classes.mobileRowOne}>
        <div className={classes.cell}>
          <Text size="xs" color="gray">
            Your Entries
          </Text>
          <Text size="sm">{raffle.total}</Text>
        </div>
        <div className={classes.cell}>
          <Text size="xs" color="gray">
            Total Entries
          </Text>
          <Text size="sm">{raffle.raffle.entries}</Text>
        </div>
      </div>
      <div className={classes.mobileRowTwo}>
        <div className={classes.cell}>
          <Text size="xs" color="gray">
            End Date
          </Text>
          <Text size="sm">
            {new dayjs(raffle.raffle.end_date_time).format("MM/DD h:mm a")}
          </Text>
        </div>
        <div className={classes.cell}>
          <Text size="xs" color="gray">
            Winner(s) Announced
          </Text>
          {raffle.raffle.winners.length > 0 && (
            <FontAwesomeIcon icon="check-circle" color="green" />
          )}
          {raffle.raffle.winners.length === 0 && (
            <FontAwesomeIcon icon="times" color="red" />
          )}
        </div>
      </div>
    </Group>
  );
}

export default UserRaffles;
