/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useFeaturedCardStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: 1450,
    width: "100%",
    margin: "10px auto",
  },
  title: {
    fontSize: 50,
  },
  datapoints: {
    fontSize: "14px",
  },
  section: {
    width: "50%",
  },
  buyButton: {
    width: "155px",
  },
  image: {
    margin: theme.spacing.sm,
    width: "500px",
    height: "500px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      // Type safe child reference in nested selectors via ref
      width: "calc(100% - 24px)",
      height: "calc(100% - 24px)",
    },
  },
  verticalDivider: {
    color: theme.white,
    height: "500px",
    alignSelf: "center",
  },
  socials: {
    marginBottom: "-10px",
    img: {
      width: "30px",
      height: "30px",
      // REMOVE BG COLOR ONCE WE ADD CARD BG COLOR
    },
  },
  horizontalDivider: {
    color: theme.white,
    width: "100%",
    margin: "10px 0",
  },
  infoWrapper: {
    width: "100%",
    minWidth: "500px",
    padding: "20px",
    minHeight: "500px",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      // Type safe child reference in nested selectors via ref
      minWidth: "unset",
      height: "auto",
    },
  },
  group: {
    marginTop: 10,
    display: "flex",
  },
  numberInput: {
    width: "82px",
  },
  connectWallet: {
    alignSelf: "center",
    marginTop: 80,
  },
}));
