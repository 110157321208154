/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useCardStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    maxWidth: "390px",
    minHeight: "595px",
    margin: "auto",
  },
  card: {
    padding: "20px",
    minHeight: "100%",
    width: "100%",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "215px",
  },
  button: {
    marginTop: 14,
  },
  group: {
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  cardTitle: {
    margin: "15px 0",
  },
  buttonWrapper: {
    marginTop: "auto",
  },
}));
