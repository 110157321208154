/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { Drawer, Group, Text, Tabs } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoreContext from "../../contexts/store/context";
import getShortAddress from "../../utils/shortAddress";
import api from "../../api/api";
import UserRaffles from "./components/UserRaffles";
import UserInfo from "./components/UserInfo";
import UserWins from "./components/UserWins";

function UserDrawer({ opened, setOpened }) {
  const wallet = useWallet();
  const [rafflePurchases, setRafflePurchases] = useState([]);
  const { user } = React.useContext(StoreContext);

  useEffect(() => {
    const getUserRaffles = async () => {
      try {
        const result = await api
          .get({
            endpoint: `/tickets/rafflePurchase/user/${user.id}`,
          })
          .then((response) => response.data);
        setRafflePurchases(result);
      } catch (e) {
        console.log(e);
      }
    };

    if (user?.id && opened) {
      getUserRaffles();
    }
  }, [user, opened]);

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        padding="xl"
        size={1000}
        styles={{
          drawer: { overflowY: "auto" },
        }}
      >
        <Tabs>
          <Tabs.Tab label="My Raffles">
            {wallet.connected && wallet.publicKey ? (
              <UserRaffles rafflePurchases={rafflePurchases} />
            ) : (
              <Text>Please connect your wallet</Text>
            )}
          </Tabs.Tab>
          <Tabs.Tab label="My Wins">
            {wallet.connected && wallet.publicKey ? (
              <UserWins />
            ) : (
              <Text>Please connect your wallet</Text>
            )}
          </Tabs.Tab>
          <Tabs.Tab label="Profile">
            {wallet.connected && wallet.publicKey ? (
              <UserInfo />
            ) : (
              <Text>Please connect your wallet</Text>
            )}
          </Tabs.Tab>
          <Group className="ml-auto hidden md:flex">
            <Text size="sm">
              {wallet.publicKey
                ? getShortAddress(wallet.publicKey.toBase58())
                : "-"}
            </Text>
            <FontAwesomeIcon icon="wallet" />
          </Group>
        </Tabs>
      </Drawer>
    </>
  );
}

export default UserDrawer;
