import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumbs, Button, Container, Group, Title } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import StoreContext from "../../contexts/store/context";
import useQuery from "../../hooks/useQuery";
import useWindowSize from "../../hooks/useWindowSize";
import InfoModal from "../InfoModal/InfoModal";
import UserDrawer from "../UserDrawer/UserDrawer";
import Wallet from "../Wallet/Wallet";

function Subheader({ connection }) {
  const wallet = useWallet();
  const { user } = React.useContext(StoreContext);
  const query = useQuery();
  const raffleId = query.get("id");
  const { mobile } = useWindowSize();
  const [isUserDrawerOpen, setIsUserDrawOpen] = useState(false);
  const [infoModalOpened, setInfoModalOpenedOpened] = useState(false);
  const location = useLocation();

  if (location.pathname === "/admin") return <></>;

  const navLinks = [
    { href: "home", link: <a href="https://www.yeahtigers.com/">Home</a> },
    {
      href: "/",
      link: <Link to="/"> Raffles</Link>,
    },
    { href: "#", link: <Link to="/#">{raffleId}</Link> },
  ];

  const items = navLinks
    .filter((link) => {
      if (link.href === "#" && !raffleId) return false;
      return true;
    })
    .map((link) => link.link);

  return (
    <div className="mt-8 mb-6">
      <Container size="1400px">
        <Group
          position="apart"
          className="flex-col md:flex-row items-start md:items-center"
        >
          <Breadcrumbs
            style={{
              minWidth: mobile ? "fit-content" : "350px",
            }}
            color="white"
            separator="→"
          >
            {items}
          </Breadcrumbs>
          <div className="text-center">
            <Title className="text-4xl md:text-4xl text-yt-white">
              RAFFLES
            </Title>
            <Group>
              <Title className="text-4xl md:text-7xl text-yt-logo-yellow">
                YEAH
              </Title>
              <Title className="text-4xl md:text-7xl text-white">TIGERS</Title>
            </Group>
          </div>

          <Group
            position="right"
            style={{ minWidth: mobile ? "fit-content" : "350px" }}
          >
            <Wallet connection={connection} />

            {user?.isAdmin && wallet.connected && (
              <Link to="/admin">
                <Button variant="filled">Admin Page</Button>
              </Link>
            )}

            <Button
              disabled={false}
              onClick={() => setIsUserDrawOpen(true)}
              variant="filled"
            >
              My Info
            </Button>
            <Button
              variant="filled"
              onClick={() => setInfoModalOpenedOpened(true)}
            >
              <FontAwesomeIcon icon="info-circle" />
            </Button>
          </Group>
        </Group>
      </Container>
      <UserDrawer opened={isUserDrawerOpen} setOpened={setIsUserDrawOpen} />
      <InfoModal
        opened={infoModalOpened}
        setOpened={setInfoModalOpenedOpened}
      />
    </div>
  );
}

export default Subheader;
