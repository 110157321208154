import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Container, Grid, Stack, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import SkeletonGrid from "../../../components/SkeletonGrid";
import useWindowSize from "../../../hooks/useWindowSize";
import getShortAddress from "../../../utils/shortAddress";

function Admins() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState();
  const { mobile } = useWindowSize();
  useEffect(() => {
    const getAdmins = async () => {
      setLoading(true);
      await api
        .get({
          endpoint: "/auth/admins",
        })
        .then((response) => setAdmins(response.data));
      setLoading(false);
    };
    getAdmins();
  }, []);
  const cols = mobile ? 2 : 14;

  if (!admins) return <></>;

  return (
    <Container size={1500} px={20}>
      <Stack>
        {loading && admins.length === 0 && (
          <SkeletonGrid amount={5} divider={false} height="50px" />
        )}
        {admins.map((admin) => {
          return (
            <Grid columns={cols} gutter="sm" key={admin?.id}>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  Wallet
                </Text>
                <Text size="ms">{getShortAddress(admin?.wallet)}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 3}>
                <Text size="sm" color="gray">
                  User Id
                </Text>
                <Text size="ms">{admin?.id}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 4}>
                <Text size="sm" color="gray">
                  Name
                </Text>
                <Text size="ms">{admin?.name || "-"}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 2}>
                <Text size="sm" color="gray">
                  Discord Id
                </Text>
                <Text size="ms">{admin?.discordId || "-"}</Text>
              </Grid.Col>
              <Grid.Col span={mobile ? 1 : 2}>
                <Text size="sm" color="gray">
                  View Account
                </Text>
                <Anchor
                  target="_blank"
                  href={`https://solscan.io/account/${admin?.wallet}`}
                >
                  Solscan <FontAwesomeIcon icon="link" />
                </Anchor>
              </Grid.Col>
            </Grid>
          );
        })}
      </Stack>
    </Container>
  );
}

export default Admins;
