import { Snackbar as SnackbarWrapper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import StoreContext from "../../contexts/store/context";

function Snackbar() {
  const { isLoading, alertState, setAlertState } =
    React.useContext(StoreContext);
  const state = alertState.alertState;

  if (!state) return <></>;
  return (
    <div>
      {!isLoading && (
        <SnackbarWrapper
          style={{ zIndex: 1000 }}
          open={state.open}
          autoHideDuration={1000}
          onClose={() =>
            setAlertState({
              state: { ...state, open: false },
            })
          }
        >
          <Alert
            onClose={() =>
              setAlertState({
                state: { ...state, open: false },
              })
            }
            severity={state.severity}
          >
            {state.message}
          </Alert>
        </SnackbarWrapper>
      )}
    </div>
  );
}

export default Snackbar;
