import {
  Divider,
  Grid,
  Space,
  Text,
  Group,
  Select,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useState, useCallback, useRef } from "react";
import api from "../../../api/api";
import Paginate from "../../../components/Paginate/Paginate";
import useWindowSize from "../../../hooks/useWindowSize";
import getShortAddress from "../../../utils/shortAddress";
import { getDirections } from "../../../constants/constants";
import SkeletonGrid from "../../../components/SkeletonGrid";

const filterOptions = [
  { value: "id", label: "User Id" },
  { value: "wallet", label: "Wallet" },
];

function Users() {
  const { mobile } = useWindowSize();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const directions = getDirections(total);
  const [value, setValue] = useState("all");
  const [skip, setSkip] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState();
  const [filterValue, setFilterValue] = useState();
  const [loading, setLoading] = useState();
  const ref = useRef();
  const cols = mobile ? 2 : 21;

  const getPurchases = useCallback(async () => {
    if (
      (!filterValue && filter) ||
      (filterValue && filter && filterValue.length < 24)
    )
      return;
    setLoading(true);
    const result = await api
      .post({
        endpoint: `/auth/users/${skip}`,
        data: {
          filter,
          filterValue,
        },
      })
      .then((response) => response?.data);
    setUsers(result?.users);
    setTotal(result?.total || 0);
    setOptions(result?.options || []);
    setLoading(false);
  }, [skip, filter, filterValue]);

  useEffect(() => {
    setSkip(0);
    setPageNumber(1);
  }, [value]);

  useEffect(() => {
    setSkip(0);
    setPageNumber(1);
    setValue("all");
    if (!filter) {
      setFilterValue(undefined);
      ref.current.value = "";
    }
  }, [filter]);

  useEffect(() => {
    getPurchases(0);
  }, [getPurchases]);

  const getAddress = (address) => {
    return (
      <Grid columns={cols} gutter="sm">
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">Name</Text>
          <Text size="ms">{address?.name || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">Street</Text>
          <Text size="ms">{address?.street || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">Apt</Text>
          <Text size="ms">{address?.street2 || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">City</Text>
          <Text size="ms">{address?.city || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">State</Text>
          <Text size="ms">{address?.state || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">Zip/ Postal Code</Text>
          <Text size="ms">{address?.zip || "-"}</Text>
        </Grid.Col>
        <Grid.Col span={mobile ? 1 : 3}>
          <Text size="sm">Country</Text>
          <Text size="ms">{address?.country || "-"}</Text>
        </Grid.Col>
      </Grid>
    );
  };

  const getRows = () => {
    return users.map((user, i) => {
      const odd = i % 2;
      return (
        <div
          key={user.id}
          className={
            odd ? "px-3 text-white bg-gray-500" : "px-3 text-white bg-gray-700 "
          }
        >
          <Space h="xs" />
          <Grid columns={cols} gutter="sm">
            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="sm">User Id</Text>
              <Tooltip label="Click to copy" withArrow>
                <button
                  type="button"
                  onClick={() => navigator.clipboard.writeText(user.id)}
                >
                  <Text size="ms">{getShortAddress(user.id)}</Text>
                </button>
              </Tooltip>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="sm">Wallet</Text>
              <Tooltip label="Click to copy" withArrow>
                <button
                  type="button"
                  onClick={() => navigator.clipboard.writeText(user.wallet)}
                >
                  <Text size="ms">{getShortAddress(user.wallet)}</Text>
                </button>
              </Tooltip>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm">Discord Id</Text>
              <Text size="ms">{user.discordId || "-"}</Text>
            </Grid.Col>

            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="sm">Merch Size</Text>
              <Text size="sm" className="break-all">
                {user.size || "-"}
              </Text>
            </Grid.Col>

            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm">Email</Text>
              <Text>{user.email || "-"}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="sm">Phone</Text>
              <Text size="md">{user.phone || "-"}</Text>
            </Grid.Col>
          </Grid>
          <Space h="xs" />
          <Divider />
          <Space h="xs" />
          {getAddress(user.address)}
          <Space h="xs" />
          <Divider />
        </div>
      );
    });
  };

  return (
    <div>
      <Group position="apart" align="end">
        <Group align="center">
          <Select
            data={filterOptions}
            value={filter}
            onChange={setFilter}
            clearable
            label="Filter"
          />
          <TextInput
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            disabled={!filter}
            placeholder="Must be exact"
            label="Filter Value"
            ref={ref}
          />
        </Group>
        <Title order={3}>{total} Results</Title>
      </Group>
      <Space h="lg" />
      {loading && (
        <SkeletonGrid amount={users?.length > 10 ? users?.length : 10} />
      )}
      {!loading && getRows()}
      <Space h="md" />
      <Paginate
        total={total}
        directions={directions}
        callBack={(toSkip) => setSkip(toSkip)}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
      />
    </div>
  );
}
export default Users;
