import { Button, Group, Menu, NumberInput, Text } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useState } from "react";
import StoreContext from "../../../contexts/store/context";

const currencies = {
  dab: "dab",
  sol: "sol",
};

export default function PurchaseActions({ data, expired, started, soldOut }) {
  if (data.type === "raffle")
    return (
      <RaffleActions
        data={data}
        expired={expired}
        started={started}
        soldOut={soldOut}
      />
    );
}

function RaffleActions({ data, expired, started, soldOut }) {
  const { sendTokens, sendingTransaction } = React.useContext(StoreContext);
  const [orderDetails, setOrderDetails] = useState({
    currency: currencies.dab,
    numberOfTickets: 1,
  });

  const wallet = useWallet();

  return (
    <div>
      <Group>
        <Button
          disabled={expired || soldOut || sendingTransaction}
          variant="outline"
          onClick={() =>
            setOrderDetails({
              currency: currencies.dab,
              numberOfTickets: orderDetails.numberOfTickets,
            })
          }
        >
          {data.ticketCost * orderDetails.numberOfTickets} $
          {process.env.REACT_APP_TOKEN}
        </Button>
        <Button
          variant="filled"
          width="152px"
          disabled={
            !wallet.connected ||
            !wallet.publicKey ||
            expired ||
            soldOut ||
            sendingTransaction
          }
          loading={sendingTransaction}
          onClick={() => sendTokens(data, orderDetails.numberOfTickets)}
        >
          Buy {orderDetails.numberOfTickets}{" "}
          {orderDetails.numberOfTickets < 2 ? "Ticket" : "Tickets"}
        </Button>

        <NumberInput
          size="sm"
          type="number"
          disabled={expired || !started}
          defaultValue={1}
          maxLength="6"
          min={1}
          onChange={(tix) =>
            setOrderDetails({
              currency: orderDetails.currency,
              numberOfTickets: tix,
            })
          }
        />
      </Group>
      {sendingTransaction && (
        <Text size="sm" className="mt-2 loading">
          Confirming transaction, this may take up to 60 seconds{" "}
        </Text>
      )}
    </div>
  );
}
