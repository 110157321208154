import React from "react";

export const initialState = {
  walletAddress: undefined,
  mint: undefined,
  isLoading: undefined,
  tokens: [],
  walletConnected: false,
  user: undefined,
  txid: undefined,
  alertState: {
    open: false,
    message: "",
    severity: undefined,
  },
};
const StoreContext = React.createContext(initialState);
export default StoreContext;
