import React from "react";
import { Modal, Button, Group, Title, Text, Space } from "@mantine/core";

function ErrorModal({ txin, opened, setOpened }) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={1} transform="uppercase">
            Error! - Purchase Failed
          </Title>
        }
        size={600}
      >
        <Text size="lg">
          We are unable to process your purchase at this time. Please record
          your burn transaction ID and contact the discord team to give you a
          raffle ticket credit.
        </Text>
        <Text size="xl">{txin}</Text>
        <Space h="lg" />
        <Group grow>
          <Button>OK</Button>
        </Group>
      </Modal>
    </>
  );
}
export default ErrorModal;
