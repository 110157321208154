/* eslint-disable no-underscore-dangle */
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction } from "@solana/web3.js";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { TOKEN_PROGRAM_ID, createTransferInstruction } from "@solana/spl-token";
import { toast, ToastContainer } from "react-toastify";
import base58 from "bs58";
import StoreContext from "./context";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import Snackbar from "../../components/Snackbar/Snackbar";
import TicketPurchaseModal from "../../components/TicketPurchaseModal/TicketPurchaseModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

function StoreProvider({ children }) {
  const wallet = useWallet();
  const [raffleCollection, setRaffleCollection] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [featuredCard, setFeaturedCard] = useState();
  const [fundsModal, setFundsModal] = useState();
  const [userRafflePurchases, setUserRafflePurchases] = useState([]);
  const [rafflePurchases, setRafflePurchases] = useState([]);
  const { connection } = useConnection();
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    ticketPurchaseSuccessful: false,
    txnID: "",
    airvault: false,
    numberOfTicketsPurchased: 0,
    description: "",
    cancelButtonLabel: "",
    callbackButtonLabel: "",
    callback: () => {},
  });
  const [tokens, setTokens] = useState({
    tokens: 0,
    sol: 0,
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState();
  const [rewardsSent, setRewardsSent] = useState(true);
  const [sendingTransaction, setSendingTransaction] = useState(false);
  const [newPurchase, setNewPurchase] = useState(true);
  const [erroOpen, setErrorOpen] = useState(false);
  const [bids, setBids] = useState([]);
  const [nonceExists, setNonceExists] = useState(false);
  const [winner, setWinner] = useState({ id: "123" });
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
    duration: 1000,
    status: "default",
  });

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    };
  }, [wallet]);

  const getRaffles = useCallback(async () => {
    await api
      .get({
        endpoint: `prismic/prismic-all/${user?.id || 0}`,
      })
      .then((result) => setRaffleCollection(result?.data));

    await api
      .get({
        endpoint: "prismic/featuredCard",
      })
      .then((result) => setFeaturedCard(result?.data));
  }, [user]);

  const getAuctions = useCallback(async () => {
    if (!user?.id) return;
    await api
      .post({
        endpoint: `auctions/user`,
        data: {
          user: user?.id,
        },
      })
      .then((result) => setAuctions(result?.data));
  }, [user]);

  const getNonce = useCallback(async () => {
    if (!wallet.publicKey) return;
    await api
      .get({
        endpoint: `auctions/bid/nonce-exists/${wallet.publicKey.toBase58()}`,
      })
      .then((response) => setNonceExists(response?.data || false));
  }, [wallet]);

  useEffect(() => {
    if (wallet.connecting || wallet.disconnecting) return;
    getRaffles();
    // getAuctions();
    // getNonce();
  }, [getRaffles, wallet]);

  const findAssociatedTokenAddress = useCallback(
    async (fromWallet, tokenMintAddress) => {
      return (
        await PublicKey.findProgramAddress(
          [
            fromWallet.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            tokenMintAddress.toBuffer(),
          ],
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
        )
      )[0];
    },
    []
  );

  const getAddresses = useCallback(async () => {
    const sourceAddress = await findAssociatedTokenAddress(
      anchorWallet?.publicKey,
      new PublicKey(process.env.REACT_APP_TOKEN_MINT_ADDRESS)
    );

    const destinationPubkey = await findAssociatedTokenAddress(
      new PublicKey(process.env.REACT_APP_DESTINATION_ADDRESS),
      new PublicKey(process.env.REACT_APP_TOKEN_MINT_ADDRESS)
    );

    return {
      sourceAddress,
      destinationPubkey,
    };
  }, [anchorWallet?.publicKey, findAssociatedTokenAddress]);

  const getBids = useCallback(async (auction) => {
    await api
      .get({
        endpoint: `bids/${auction}`,
      })
      .then((response) => setBids(response?.data || []));
  }, []);

  const createTransation = useCallback(
    async (sourceAddress, destinationPubkey, amount) => {
      const transaction = new Transaction().add(
        createTransferInstruction(
          sourceAddress,
          destinationPubkey,
          anchorWallet?.publicKey,
          1e9 * amount,
          [],
          TOKEN_PROGRAM_ID
        )
      );

      transaction.recentBlockhash = (
        await connection.getRecentBlockhash("max")
      ).blockhash;

      transaction.feePayer = wallet.publicKey;
      return transaction;
    },
    [anchorWallet?.publicKey, wallet.publicKey, connection]
  );

  const sendTransaction = useCallback(
    async (signature, raffleData, tickets) => {
      const purchase = await api
        .post({
          endpoint: `tickets/rafflePurchase`,
          data: {
            raffle: raffleData._id,
            prismicId: raffleData.prismicId,
            tickets,
            signature: signature.serialize(),
            user: user.id,
          },
        })
        .then((response) => response?.data);
      return purchase;
    },
    [user]
  );

  const createNonce = useCallback(async () => {
    if (!wallet.publicKey) return;
    const joinToast = toast.loading(`Creating nonce account...`);
    try {
      const txSerialized = await api.get({
        endpoint: `nonce-account/${wallet?.publicKey?.toBase58()}`,
        responseType: "application/octet-stream",
      });

      const tx = Transaction.from(Buffer.from(txSerialized.data));
      tx.feePayer = wallet.publicKey;
      const signed = await wallet.signTransaction(tx);
      await api.post({
        endpoint: "send-transaction",
        data: {
          signature: signed.serialize(),
          wallet: wallet.publicKey.toBase58(),
        },
      });
      toast.update(joinToast, {
        render: `Nonce account created!`,
        type: "success",
        isLoading: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 4000,
      });
    } catch (e) {
      console.log(e);
      toast.update(joinToast, {
        render: `Error: unable to create nonce account!`,
        type: "error",
        isLoading: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 4000,
      });
    }
  }, [wallet]);

  const placeBid = useCallback(
    async (auction, amount) => {
      if (!wallet.publicKey) {
        throw new Error("Wallet not connected");
      }
      const joinToast = toast.loading(`Placing bid...`);
      try {
        const result = await api.post({
          endpoint: "/bid/instructions",
          responseType: "application/octet-stream",
          data: {
            amount,
            wallet: wallet.publicKey.toBase58(),
          },
        });
        const tx = Transaction.from(Buffer.from(result.data));
        const signed = await wallet.signTransaction(tx);
        const bid = await api.post({
          endpoint: "/bid/create",
          data: {
            signature: signed.serialize(),
            auction: auction._id,
            bid: amount,
            wallet: wallet.publicKey.toBase58(),
            blockhash: tx.recentBlockhash,
          },
        });
        toast.update(joinToast, {
          render: `Bid successful!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
        await getBids(auction._id);
        return bid;
      } catch (e) {
        toast.update(joinToast, {
          render: `Error: Bid failed!`,
          type: "error",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
        console.log(e);
        return undefined;
      }
    },
    [wallet, getBids]
  );

  // const placeBid = useCallback(
  //   async (auction, amount) => {
  //     const joinToast = toast.loading(`Placing bid...`);
  //     try {
  //       const result = await api.post({
  //         endpoint: "/bid/nonce",
  //         responseType: "application/octet-stream",
  //         data: {
  //           auction,
  //           amount,
  //           wallet: wallet.publicKey.toBase58(),
  //         },
  //       });
  //       const tx2 = Transaction.from(Buffer.from(result.data));
  //       const signed2 = await wallet.signTransaction(tx2);
  //       const bid = await api.post({
  //         endpoint: "/save-bid",
  //         data: {
  //           signature: signed2.serialize(),
  //           auction: auction._id,
  //           amount,
  //           user: user._id,
  //         },
  //       });
  //       toast.update(joinToast, {
  //         render: `Bid successful!`,
  //         type: "success",
  //         isLoading: false,
  //         closeOnClick: true,
  //         closeButton: true,
  //         autoClose: 4000,
  //       });
  //       await getBids(auction._id);
  //       return bid;
  //     } catch (e) {
  //       toast.update(joinToast, {
  //         render: `Error: Bid failed!`,
  //         type: "error",
  //         isLoading: false,
  //         closeOnClick: true,
  //         closeButton: true,
  //         autoClose: 4000,
  //       });
  //       return undefined;
  //     }
  //   },
  //   [wallet, user, getBids]
  // );

  // const burnAndPurchase = useCallback(
  //   async (sourceAddress, raffleData, tickets, cost) => {
  //     const txid = await burnTokens(
  //       wallet,
  //       null,
  //       new PublicKey(sourceAddress.toString()),
  //       null,
  //       tickets * cost,
  //       true,
  //       true
  //     );

  //     setTxin(txid);

  //     try {
  //       const postTransaction = await api
  //         .post({
  //           endpoint: `/rafflePurchase`,
  //           data: {
  //             raffle: raffleData,
  //             prismicId: raffleData.prismicId,
  //             tickets,
  //             prize: raffleData.prize,
  //             ticketTransactionId: txid,
  //             user,
  //           },
  //         })
  //         .then((response) => response.data);
  //       return { txid, purchaseId: postTransaction._id };
  //     } catch (e) {
  //       setErrorOpen(true);
  //       return { txid, purchaseId: undefined };
  //     }
  //   },
  //   [user, wallet]
  // );

  const getUser = useCallback(async () => {
    const result = await api.get({
      endpoint: `/users/wallet/${anchorWallet?.publicKey}`,
    });
    setUser(result?.data);
  }, [anchorWallet?.publicKey]);

  useEffect(() => {
    if (anchorWallet?.publicKey) getUser();
  }, [anchorWallet?.publicKey, getUser]);

  const sendTokens = useCallback(
    async (raffleData, numberOfTickets) => {
      if (!anchorWallet?.publicKey) return;
      if (!user) {
        toast.error("Not signed in, please try again or refresh the page");
        await getUser();
        return;
      }
      const totalCost =
        numberOfTickets * parseInt(raffleData.ticketCost || 1, 10);

      // if (!tokens.token || tokens.token < totalCost) {
      //   setFundsModal("token");
      //   return;
      // }
      // if (tokens.sol === 0) {
      //   setFundsModal("sol");
      //   return;
      // }
      const joinToast = toast.loading(`Buying ${numberOfTickets} ticket(s)...`);
      setSendingTransaction(true);
      const { sourceAddress, destinationPubkey } = await getAddresses();

      // // add back * parseInt(raffleData.ticketCost, 10)
      const transaction = await createTransation(
        sourceAddress,
        destinationPubkey,
        numberOfTickets * parseInt(raffleData.ticketCost || 1, 10)
      );

      try {
        const signature = await wallet.signTransaction(transaction);
        const purchase = await sendTransaction(
          signature,
          raffleData,
          numberOfTickets,
          parseInt(raffleData.ticketCost || 0, 10)
        );

        setModal({
          isOpen: true,
          title: "Raffle Entry Confirmation",
          ticketPurchaseSuccessful: true,
          txnID: purchase?.ticketTransactionId,
          numberOfTicketsPurchased: purchase?.tickets,
          raffleName: raffleData.data.title,
          raffle: raffleData,
          user,
        });

        toast.update(joinToast, {
          render: `Confirming Transaction...`,
          type: "info",
          isLoading: true,
        });

        await connection.confirmTransaction(
          purchase.ticketTransactionId,
          "finalized"
        );

        toast.update(joinToast, {
          render: `Confirming Transaction...`,
          type: "info",
          isLoading: true,
        });

        await connection.confirmTransaction(
          purchase.ticketTransactionId,
          "finalized"
        );

        toast.update(joinToast, {
          render: `Purchase successful!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });

        await getRaffles();
      } catch (e) {
        console.log(e);
        toast.update(joinToast, {
          render: `Error: ${e.message}`,
          type: "error",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } finally {
        setSendingTransaction(false);
      }
    },
    [
      anchorWallet?.publicKey,
      user,
      getAddresses,
      createTransation,
      getUser,
      wallet,
      sendTransaction,
      getRaffles,
      connection,
    ]
  );

  // const placeBid = useCallback(
  //   async (auction, bid) => {
  //     if (!anchorWallet?.publicKey) return;
  //     if (!user) {
  //       toast.error("Not signed in, please try again or refresh the page");
  //       await getUser();
  //       return;
  //     }
  //     const totalCost = parseInt(bid || 1, 10);

  //     if (!tokens.token || tokens.token < totalCost) {
  //       setFundsModal("token");
  //       return;
  //     }
  //     if (tokens.sol === 0) {
  //       setFundsModal("sol");
  //       return;
  //     }
  //     const joinToast = toast.loading(`Placing bid for ${bid} $AIR`);
  //     setSendingTransaction(true);
  //     const { sourceAddress, destinationPubkey } = await getAddresses();

  //     // // add back * parseInt(raffleData.ticketCost, 10)
  //     const transaction = await createTransation(
  //       sourceAddress,
  //       destinationPubkey,
  //       bid
  //     );

  //     try {
  //       const signature = await wallet.signTransaction(transaction);
  //       const purchase = await sendBid(signature, auction, totalCost);
  //       setModal({
  //         isOpen: true,
  //         title: "Raffle Entry Confirmation",
  //         ticketPurchaseSuccessful: purchase.ticketPurchaseSuccessful,
  //         txnID: purchase.ticketTransactionId,
  //         numberOfTicketsPurchased: purchase.tickets,
  //         raffleName: auction.data.title,
  //         auction,
  //         user,
  //       });
  //       toast.update(joinToast, {
  //         render: `Purchase successful!`,
  //         type: "success",
  //         isLoading: false,
  //         closeOnClick: true,
  //         closeButton: true,
  //         autoClose: 4000,
  //       });
  //       getBids();
  //     } catch (e) {
  //       console.log(e);
  //       toast.update(joinToast, {
  //         render: `Error: Purchase cancelled`,
  //         type: "error",
  //         isLoading: false,
  //         closeOnClick: true,
  //         closeButton: true,
  //         autoClose: 4000,
  //       });
  //     } finally {
  //       setSendingTransaction(false);
  //     }
  //   },
  //   [
  //     anchorWallet?.publicKey,
  //     user,
  //     tokens,
  //     getAddresses,
  //     createTransation,
  //     getUser,
  //     wallet,
  //     sendBid,
  //     getBids,
  //   ]
  // );

  useEffect(() => {
    const getTokens = async () => {
      try {
        const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
          anchorWallet?.publicKey,
          {
            programId: TOKEN_PROGRAM_ID,
          }
        );

        const dabToken = tokenAccounts.value.find(
          (token) =>
            token.account.data.parsed.info.mint ===
            process.env.REACT_APP_TOKEN_MINT_ADDRESS
        );
        const tokenAmount = parseInt(
          dabToken?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0,
          10
        );
        // const solanaBalance = await connection.getBalance(
        //   anchorWallet?.publicKey,
        //   "confirmed"
        // );

        setTokens({
          token: tokenAmount,
          sol: parseInt(
            dabToken?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0,
            10
          ),
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (wallet.connected && anchorWallet?.publicKey) {
      getTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.connected, anchorWallet?.publicKey]);

  const purchaseWithVault = useCallback(
    // eslint-disable-next-line consistent-return
    async (raffle, tickets) => {
      const joinToast = toast.loading(
        `Buying ${tickets} ticket(s) with $AIR vault...`
      );
      try {
        if (!user) {
          toast.update(joinToast, {
            render: "Not signed in, please try again or refresh the page",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 4000,
          });
          return undefined;
        }

        const funds = await api
          .post({
            endpoint: "/vault/get-balance",
            data: {
              address: wallet.publicKey.toBase58(),
            },
          })
          .then((response) => response?.data?.result || 0);
        if (funds < tickets)
          toast.update(joinToast, {
            render: "Not enough funds in $AIR vault",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 4000,
          });
        else {
          setSendingTransaction(true);
          const params = await api
            .post({
              endpoint: "/vault/generate-params",
              data: {
                address: wallet.publicKey.toBase58(),
                raffle: raffle._id,
                tickets,
              },
            })
            .then((response) => response.data);
          if (params) {
            const encodedMessage = new TextEncoder().encode(
              params.result.unsigned
            );
            const signedMessage = await wallet.signMessage(encodedMessage);

            const consume = await api
              .post({
                endpoint: "/vault/consume",
                data: {
                  params: [
                    ...params.result.params,
                    base58.encode(signedMessage),
                  ],
                  user: user._id,
                  tickets,
                  raffle: raffle._id,
                },
              })
              .then((response) => response.data);

            toast.update(joinToast, {
              render: `Ticket purchase complete!`,
              type: "success",
              isLoading: false,
              closeOnClick: true,
              closeButton: true,
              autoClose: 4000,
            });
            setModal({
              isOpen: true,
              title: "Raffle Entry Confirmation",
              ticketPurchaseSuccessful: true,
              txnID: consume.ticketTransactionId,
              airvault: true,
              numberOfTicketsPurchased: tickets,
              raffleName: raffle.data.title,
              raffle,
              user,
            });
            return consume;
          }
        }
      } catch (e) {
        console.log(e);
        toast.update(joinToast, {
          render: `Purchase Cancelled`,
          type: "error",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } finally {
        setSendingTransaction(false);
      }
    },
    [user, wallet]
  );

  const getAllRaffleEntries = useCallback(async (skip, id) => {
    const result = await api
      .get({
        endpoint: `tickets/rafflePurchase/raffle/${id}/skip/${skip || 0}`,
      })
      .then((response) => response.data);
    setRafflePurchases(result);
  }, []);

  const getUserRaffleEntries = useCallback(
    async (id) => {
      if (!user.id) return;
      const result = await api
        .get({
          endpoint: `tickets/rafflePurchase/raffle/${id}/user/${user.id}`,
        })
        .then((response) => response.data);
      setUserRafflePurchases(result);
    },
    [user?.id]
  );

  const getRaffleDetails = useCallback(
    async (skip, id) => {
      if (user) await getUserRaffleEntries(id);
      await getAllRaffleEntries(skip, id);
    },
    [getAllRaffleEntries, getUserRaffleEntries, user]
  );

  const contextValue = useMemo(
    () => ({
      wallet,
      anchorWallet,
      tokens,
      alertState,
      winner,
      user,
      rewardsSent,
      modal,
      isAdmin,
      newPurchase,
      raffleCollection,
      featuredCard,
      fundsModal,
      sendingTransaction,
      auctions,
      bids,
      nonceExists,
      userRafflePurchases,
      rafflePurchases,
      // getAuctions,
      // getNonce,
      getBids,
      purchaseWithVault,
      setFundsModal,
      setAlertState,
      setWinner,
      setRewardsSent,
      setTokens,
      setModal,
      setIsAdmin,
      setUser,
      sendTokens,
      setNewPurchase,
      placeBid,
      createNonce,
      getRaffleDetails,
    }),
    [
      wallet,
      anchorWallet,
      alertState,
      winner,
      user,
      rewardsSent,
      tokens,
      modal,
      isAdmin,
      newPurchase,
      raffleCollection,
      featuredCard,
      fundsModal,
      sendingTransaction,
      auctions,
      bids,
      nonceExists,
      userRafflePurchases,
      rafflePurchases,
      // getAuctions,
      // getNonce,
      getBids,
      purchaseWithVault,
      setFundsModal,
      setRewardsSent,
      setTokens,
      setModal,
      setIsAdmin,
      setUser,
      sendTokens,
      setNewPurchase,
      placeBid,
      createNonce,
      getRaffleDetails,
    ]
  );

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
      <div>
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      {modal.isOpen && (
        <TicketPurchaseModal
          opened={modal.isOpen}
          setIsOpen={() => setModal({ ...modal, isOpen: false })}
        />
      )}
      <ErrorModal opened={erroOpen} setOpened={setErrorOpen} />
      <Snackbar alertState={alertState} setAlertState={setAlertState} />
    </StoreContext.Provider>
  );
}

export default StoreProvider;
