import { Divider, Skeleton, Space } from "@mantine/core";
import React from "react";

function SkeletonGrid({ amount, height, divider = true }) {
  return (
    <>
      {Array(amount).fill(
        <>
          <Skeleton height={height || "59px"} />
          {divider && (
            <>
              <Space h="xs" />
              <Divider />
            </>
          )}
        </>
      )}
    </>
  );
}
export default SkeletonGrid;
