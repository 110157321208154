import React from "react";
import { Text } from "@mantine/core";
import Countdown from "react-countdown";
import { useDateCountdownStyles } from "./style";

function DateCountdownCompact({ datetime, started }) {
  const { classes } = useDateCountdownStyles();
  if (!datetime) {
    return (
      <Text weight={800} size="md">
        CLOSED!
      </Text>
    );
  }

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Text weight={800} size="md">
          CLOSED!
        </Text>
      );
    }
    return (
      <>
        <div className={classes.compactGroup}>
          {/* <Text weight="bold">
            {!started && !completed ? "Starts In:" : ""}
          </Text> */}
          <Text className={classes.compactValue}>
            {days < 10 ? `0${days}D` : `${days}D`}
          </Text>
          <Text className={classes.compactValue}>
            {hours < 10 ? `0${hours}H` : `${hours}H`}
          </Text>

          <Text className={classes.compactValue}>
            {minutes < 10 ? `0${minutes}M` : `${minutes}M`}
          </Text>

          <Text className={classes.compactValue}>
            {seconds < 10 ? `0${seconds}S` : `${seconds}S`}
          </Text>
        </div>
      </>
    );
  };
  return <Countdown date={datetime} renderer={renderCountdown} />;
}

export default DateCountdownCompact;
