import React from "react";
import { ActionIcon, Group, Image } from "@mantine/core";
import { Icon } from "@material-ui/core";

export default function Socials({ discord, twitter }) {
  return (
    <Group position="right" spacing="sm">
      {discord?.url && (
        <ActionIcon
          component="a"
          color="white"
          variant="outline"
          radius="xl"
          href={discord?.url}
          target="_blank"
          rel="noreferrer"
        >
          <Image src="/img/discord.png" />
        </ActionIcon>
      )}
      {twitter?.url && (
        <ActionIcon
          component="a"
          color="white"
          variant="outline"
          radius="xl"
          href={twitter?.url}
          target="_blank"
          rel="noreferrer"
        >
          <Image src="/img/twitter.png" />
        </ActionIcon>
      )}
    </Group>
  );
}
