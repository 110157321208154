import { Card, Container, Loader, Tabs, Text, Title } from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import api from "../../api/api";
import StoreContext from "../../contexts/store/context";
import Admins from "./components/Admins";
import AllPurchases from "./components/AllPurchases";
import Stats from "./components/Stats";
import Users from "./components/Users";
import Winners from "./components/Winners";

function Admin() {
  const { user } = React.useContext(StoreContext);
  const admin = !!user?.isAdmin;
  const wallet = useWallet();

  if (!admin || !wallet.connected)
    return (
      <Container>
        <Text>404 Not Found!</Text>
      </Container>
    );
  return (
    <Container className="w-full -mt-5 md:mt-0" size={1500} p="lg">
      <Title className="">Admin Dashboard</Title>
      <Stats />
      <Card className="w-full">
        <Card.Section p="lg">
          <Container size={1500} className="w-full">
            <Tabs grow>
              <Tabs.Tab label="All Raffles">{admin && <Winners />}</Tabs.Tab>
              <Tabs.Tab label="Admins">
                <Admins />
              </Tabs.Tab>
              <Tabs.Tab label="Raffle Tickets">
                <AllPurchases />
              </Tabs.Tab>
              <Tabs.Tab label="Users">
                <Users />
              </Tabs.Tab>
            </Tabs>
          </Container>
        </Card.Section>
      </Card>
    </Container>
  );
}

export default Admin;
