import { useEffect, useState } from "react";
// Hook
function useCountdown(startDate, endDate) {
  const [ended, setEnded] = useState(
    new Date().getTime() - new Date(endDate).getTime() > 0
  );
  const [started, setStarted] = useState(
    new Date().getTime() - new Date(startDate || new Date()).getTime() > 0
  );

  useEffect(() => {
    const checkTime = () => {
      setEnded(new Date().getTime() - new Date(endDate).getTime() > 0);
      setStarted(new Date().getTime() - new Date(startDate).getTime() > 0);
    };
    const timerID = setInterval(() => checkTime(), 1000);

    return () => clearInterval(timerID);
  });
  return [started, ended];
}

export default useCountdown;
