import { Container, Space } from "@mantine/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FeaturedCard from "../../components/FeaturedCard/FeaturedCard";
import RaffleData from "../../components/RaffleData/RaffleData";
import StoreContext from "../../contexts/store/context";

function Raffle() {
  const { raffleCollection } = React.useContext(StoreContext);
  const { id } = useParams();
  const raffleData = raffleCollection.find((raffle) => raffle.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!raffleData) return <></>;
  return (
    <Container size="1400px">
      <FeaturedCard data={raffleData} />
      <Space h="xl" />
      <RaffleData raffleData={raffleData} />
      <Space h="xl" />
    </Container>
  );
}

export default Raffle;
