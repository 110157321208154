import { Badge, Group } from "@mantine/core";
import React from "react";

export default function BadgeSummary({ items }) {
  return (
    <Group className="my-3 pb-2 md:flex-nowrap" position="left" spacing="xs">
      {items.map((item) => {
        return (
          <Badge size="lg" variant="outline" className="text-xs">
            {item.label}: {item.value || 0}
          </Badge>
        );
      })}
    </Group>
  );
}
