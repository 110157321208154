/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useModalStyles = createStyles(() => ({
  modal: {},
  content: { display: "flex", flexDirection: "column" },
  title: {
    fontSize: "50px",
    fontWeight: "bold",
  },
  body: {
    fontSize: "30px",
    lineHeight: 1,
    margin: "20px 0",
  },
  bodyError: {
    fontSize: "20px",
    lineHeight: 1,
    margin: "20px 0",
    color: "red",
  },
}));
