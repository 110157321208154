import React, { useEffect, useState } from "react";
import { Container, Divider, Group, Space, Text, Title } from "@mantine/core";
import dayjs from "dayjs";
import StoreContext from "../../../contexts/store/context";
import api from "../../../api/api";

function UserWins() {
  const { user } = React.useContext(StoreContext);
  const [wins, setWins] = useState([]);
  useEffect(() => {
    const getWins = async () => {
      const result = await api
        .get({
          endpoint: `/users/raffle-wins/user/${user.id}`,
        })
        .then((response) => response.data);
      setWins(result);
    };
    if (user) getWins();
  }, [user]);
  return (
    <Container>
      {(!wins || wins.length === 0) && (
        <div>
          <Space h="md" />
          <Text>No wins yet! Test your luck and enter our latest raffle!</Text>
        </div>
      )}
      {wins?.map((win) => {
        return (
          <div key={win.id}>
            <Space h="md" />
            <Group>
              <Container>
                <Title order={5}>Raffle</Title>
                <Text>{win.title}</Text>
              </Container>
              <Container>
                <Title order={5}>Prize</Title>
                <Text>{win.prize}</Text>
              </Container>
              <Container>
                <Title order={5}>Claim Id</Title>
                <Text>{win.id}</Text>
              </Container>
              <Container>
                <Title order={5}>Date</Title>
                <Text>{new dayjs(win.createdAt).format("MM/DD h:mm a")}</Text>
              </Container>
            </Group>
            <Space h="md" />
            <Divider />
          </div>
        );
      })}
    </Container>
  );
}

export default UserWins;
