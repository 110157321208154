import React from "react";
import { Text, Group, Stack } from "@mantine/core";
import Countdown from "react-countdown";
import { useDateCountdownStyles } from "./style";

function DateCountdown({ datetime }) {
  const { classes } = useDateCountdownStyles();
  if (!datetime) {
    return (
      <Text weight={800} size="xl">
        CLOSED!
      </Text>
    );
  }

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Text weight={800} size="xl">
          CLOSED!
        </Text>
      );
    }
    return (
      <Group spacing={35} className={classes.group}>
        <Stack spacing={0}>
          <Text className={`${classes.value} title`}>
            {days < 10 ? `0${days}` : days}
          </Text>
          <Text className={classes.label}>Days</Text>
        </Stack>
        <Stack spacing={0}>
          <Text className={`${classes.value} title`}>
            {hours < 10 ? `0${hours}` : hours}
          </Text>
          <Text className={classes.label}>Hours</Text>
        </Stack>
        <Stack spacing={0}>
          <Text className={`${classes.value} title`}>
            {minutes < 10 ? `0${minutes}` : minutes}
          </Text>
          <Text className={classes.label}>Mins</Text>
        </Stack>
        <Stack spacing={0}>
          <Text className={`${classes.value} title`}>
            {seconds < 10 ? `0${seconds}` : seconds}
          </Text>
          <Text className={classes.label}>Secs</Text>
        </Stack>
      </Group>
    );
  };
  return (
    <div style={{ width: 350 }}>
      <Countdown date={datetime} renderer={renderCountdown} />
    </div>
  );
}

export default DateCountdown;
