import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Title,
  Card,
  Divider,
  Space,
  Text,
  Grid,
  Group,
  Container,
  Loader,
} from "@mantine/core";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeaturedCard from "../../components/FeaturedCard/FeaturedCard";
import getShortAddress from "../../utils/shortAddress";
import StoreContext from "../../contexts/store/context";
import useWindowSize from "../../hooks/useWindowSize";

function Winners() {
  const { raffleCollection } = React.useContext(StoreContext);
  const { id } = useParams();
  const raffleData = raffleCollection.find((raffle) => raffle.id === id);
  const { mobile } = useWindowSize();

  const cols = mobile ? 2 : 12;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getWinnerItem = (rafflePurchase, idx) => {
    return (
      <div key={idx}>
        <Divider />
        <Space h="md" />
        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 2 : 3}>
            <Text size="xs" color="gray">
              Wallet
            </Text>
            <Text size="sm">
              {rafflePurchase?.user?.wallet
                ? getShortAddress(rafflePurchase.user.wallet)
                : "-"}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Ticket(s)
            </Text>
            <Text size="sm">{rafflePurchase.tickets}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Total ${process.env.REACT_APP_TOKEN}
            </Text>
            <Text size="sm">
              {rafflePurchase.tickets * parseInt(raffleData.ticketCost, 10)}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Purchase TXN
            </Text>
            <Text size="sm">
              <a
                href={`https://solscan.io/tx/${rafflePurchase.ticketTransactionId}`}
                target="_blank"
                rel="noreferrer"
              >
                {getShortAddress(rafflePurchase.ticketTransactionId)}{" "}
                <FontAwesomeIcon icon="link" />
              </a>
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Purchase Date
            </Text>
            <Text size="sm">
              {new dayjs(rafflePurchase.createdAt).format("MM/DD h:mm a")}
            </Text>
          </Grid.Col>
        </Grid>
        <Space h="md" />
      </div>
    );
  };

  if (!raffleData) return <></>;

  return (
    <Container size="1400px">
      <>
        {raffleData && (
          <FeaturedCard data={raffleData} winners={raffleData.winners} />
        )}
        <Space h="xl" />
        <Container size="1100px">
          <Card shadow="sm" p="xl">
            <Space h="xl" />
            <Card.Section>
              <Container>
                <Group position="apart" align="end">
                  <Title>Winners</Title>
                </Group>
                <Divider />
              </Container>
              {!raffleData?.winningTickets && (
                <Container px={20}>
                  <Space h="md" />
                  <Group align="center">
                    <Text size="lg">
                      Winners will be announced shortly! Please refresh after a
                      few minutes
                    </Text>
                    <Loader />
                  </Group>
                </Container>
              )}
              <Container px={20}>
                {raffleData?.winningTickets ? (
                  raffleData.winningTickets.map((rafflePurchase, idx) => {
                    return getWinnerItem(rafflePurchase, idx);
                  })
                ) : (
                  <Loader style={{ marginLeft: "50%" }} />
                )}
              </Container>
            </Card.Section>
            <Space h="xl" />
          </Card>
        </Container>
        <Space h="xl" />
      </>
    </Container>
  );
}

export default Winners;
