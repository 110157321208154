import React from "react";

import RaffleCard from "./components/RaffleCard";

function Card({ data }) {
  if (data.type === "raffle")
    return (
      <RaffleCard
        startDate={data.startDate}
        endDate={data.endDate}
        title={data.data.title}
        image={data.data.image}
        entries={data.entries}
        numberOfWinners={data.data.number_of_winners}
        userTotal={data.userTotal}
        id={data.id}
        _id={data.id}
      />
    );
}

export default Card;
