import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Divider,
  Grid,
  Space,
  Text,
  Group,
  Select,
  Tooltip,
  TextInput,
  Modal,
  Title,
  Stack,
  Button,
  Checkbox,
  InputWrapper,
} from "@mantine/core";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import api from "../../../api/api";
import Paginate from "../../../components/Paginate/Paginate";
import useWindowSize from "../../../hooks/useWindowSize";
import getShortAddress from "../../../utils/shortAddress";
import { getDirections } from "../../../constants/constants";
import SkeletonGrid from "../../../components/SkeletonGrid";
import StoreContext from "../../../contexts/store/context";

const filterOptions = [
  { value: "id", label: "Ticket Id" },
  { value: "user", label: "User Id" },
  { value: "ticketTransactionId", label: "Purchase TXN" },
  { value: "wallet", label: "Wallet" },
  { value: "ticketPurchaseSuccessful", label: "Purchase Successful" },
];

function AllPurchases() {
  const { user } = React.useContext(StoreContext);
  const { mobile } = useWindowSize();
  const [purchases, setPurchases] = useState([]);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const directions = getDirections(total);
  const [value, setValue] = useState("all");
  const [skip, setSkip] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState();
  const [filterValue, setFilterValue] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [checked, setChecked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const cols = mobile ? 2 : 32;

  const getPurchases = useCallback(async () => {
    if (
      (!filterValue && filter) ||
      (filterValue && filter && filterValue.length < 24)
    )
      return;
    const result = await api
      .post({
        endpoint: `/auth/tickets/${skip}`,
        data: {
          raffle: value === "all" ? undefined : value,
          filter,
          filterValue,
        },
      })
      .then((response) => response?.data);
    setPurchases(result?.purchases);
    setTotal(result?.total || 0);
    setOptions(result?.options || []);
    setLoading(false);
  }, [value, skip, filter, filterValue]);

  const updateTicket = async () => {
    setSaving(true);
    try {
      await api.post({
        endpoint: `auth/rafflePurchase/${selectedTicket._id}`,
        data: {
          ticketPurchaseSuccessful: checked,
        },
        admin: user.id,
      });
      toast.success(`Updated ticket successfully!`);
      setSelectedTicket(undefined);
      getPurchases();
    } catch (e) {
      console.log(e);
      toast.error(`Updating ticket failed!`);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setSkip(0);
    setPageNumber(1);
  }, [value]);

  useEffect(() => {
    setSkip(0);
    setPageNumber(1);
    setValue("all");
    if (filter === "ticketPurchaseSuccessful") {
      setFilterValue(false);
    }
    if (!filter) {
      setFilterValue(undefined);
      ref.current.value = "";
    }
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    getPurchases(0);
  }, [getPurchases]);

  useEffect(() => {
    setChecked(selectedTicket?.ticketPurchaseSuccessful);
  }, [selectedTicket]);

  const getRows = () => {
    return purchases.map((purchase) => {
      return (
        <div key={purchase.id}>
          <Space h="xs" />
          <Grid columns={cols} gutter="sm">
            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="xs" color="gray">
                Ticket Id
              </Text>
              <Tooltip label="Click to Edit" withArrow>
                <button
                  type="button"
                  onClick={() => setSelectedTicket(purchase)}
                >
                  <Text size="sm">{getShortAddress(purchase._id)}</Text>
                </button>
              </Tooltip>
            </Grid.Col>

            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="xs" color="gray">
                Raffle Name
              </Text>
              <Text size="sm">{purchase.raffle.title}</Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="xs" color="gray">
                Raffle Id
              </Text>
              <Tooltip label="Click to copy" withArrow>
                <button
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText(purchase.raffle.id)
                  }
                >
                  <Group spacing="xs">
                    <Text size="sm">{getShortAddress(purchase.raffle.id)}</Text>
                    <FontAwesomeIcon icon="copy" className="text-gray-400" />
                  </Group>
                </button>
              </Tooltip>
            </Grid.Col>

            <Grid.Col span={mobile ? 1 : 2}>
              <Text size="xs" color="gray">
                # Tickets
              </Text>
              <Text size="sm" className="break-all">
                {purchase.tickets}
              </Text>
            </Grid.Col>

            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="xs" color="gray">
                Purchase TXN
              </Text>
              {!purchase.airvault ? (
                <Anchor
                  target="_blank"
                  href={`https://solscan.io/tx/${purchase.ticketTransactionId}`}
                >
                  <Text size="sm">
                    {getShortAddress(purchase.ticketTransactionId)}
                  </Text>
                </Anchor>
              ) : (
                `$AIR Vault ${purchase.ticketTransactionId}`
              )}
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="xs" color="gray">
                Purchase Successful
              </Text>
              <Text size="md">
                {purchase.ticketPurchaseSuccessful && (
                  <FontAwesomeIcon icon="check-circle" color="green" />
                )}
                {!purchase.ticketPurchaseSuccessful && (
                  <FontAwesomeIcon icon="times" color="red" />
                )}
              </Text>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="xs" color="gray">
                User Id
              </Text>

              <Tooltip label="Click to copy" withArrow>
                <button
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText(purchase.user?.id)
                  }
                >
                  <Group spacing="xs">
                    <Text size="sm">
                      {getShortAddress(purchase.user?.id) || "-"}
                    </Text>
                    <FontAwesomeIcon icon="copy" className="text-gray-400" />
                  </Group>
                </button>
              </Tooltip>
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 3}>
              <Text size="xs" color="gray">
                User Wallet
              </Text>
              {purchase?.user?.wallet ? (
                <Anchor
                  target="_blank"
                  href={`https://solscan.io/account/${purchase.user.wallet}`}
                >
                  <Text size="sm">{getShortAddress(purchase.user.wallet)}</Text>
                </Anchor>
              ) : (
                "-"
              )}
            </Grid.Col>
            <Grid.Col span={mobile ? 1 : 4}>
              <Text size="xs" color="gray">
                Discord Id
              </Text>
              <Text size="sm" className="break-all" se>
                {purchase.user?.discordId || "-"}
              </Text>
            </Grid.Col>
          </Grid>
          <Space h="xs" />
          <Divider />
        </div>
      );
    });
  };

  return (
    <div>
      <Group position="apart" align="end">
        <Group align="center">
          <Select
            disabled={filter}
            data={options}
            value={value}
            onChange={setValue}
            label="Raffle"
          />
          <Divider
            orientation="vertical"
            style={{ height: "35px" }}
            className="my-auto"
          />
          <Select
            data={filterOptions}
            value={filter}
            onChange={setFilter}
            clearable
            label="Filter"
          />
          {filter !== "ticketPurchaseSuccessful" ? (
            <TextInput
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              disabled={!filter}
              placeholder="Must be exact"
              label="Filter Value"
              ref={ref}
              required
            />
          ) : (
            <InputWrapper label="Filter Value" required>
              <Checkbox
                checked={filterValue}
                onChange={(event) =>
                  setFilterValue(event.currentTarget.checked)
                }
              />
            </InputWrapper>
          )}
        </Group>
        <Title order={3}>{total} Results</Title>
      </Group>
      <Space h="lg" />
      {loading && (
        <SkeletonGrid amount={purchases.length > 10 ? purchases.length : 10} />
      )}
      {!loading && getRows()}
      <Space h="md" />
      <Paginate
        total={total}
        directions={directions}
        callBack={(toSkip) => setSkip(toSkip)}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
      />
      <Modal
        title={<Title order={3}>Edit Ticket</Title>}
        opened={!!selectedTicket}
        onClose={() => setSelectedTicket(undefined)}
      >
        {selectedTicket && (
          <>
            <Text>
              To manually override &quot;Purchase Successful&quot; update the
              checkbox below and click save.
            </Text>
            <Text>
              Please <b>check the transaction on Solscan</b> before doing this.
            </Text>

            <Anchor
              target="_blank"
              href={`https://solscan.io/tx/${selectedTicket.ticketTransactionId}`}
            >
              Go to Solscan:{" "}
              {getShortAddress(selectedTicket.ticketTransactionId)}
            </Anchor>
            <Space h="md" />
            <Stack spacing="sm">
              <div>
                <Text size="sm" color="gray">
                  Raffle
                </Text>
                <Text size="sm" className="break-all">
                  {selectedTicket?.raffle?.title}
                </Text>
              </div>

              <div>
                <Text size="sm" color="gray">
                  Tickets Id
                </Text>
                <Text size="sm" className="break-all">
                  {selectedTicket?._id}
                </Text>
              </div>
              <div>
                <Text size="sm" color="gray">
                  Wallet
                </Text>
                <Text size="sm" className="break-all">
                  {selectedTicket?.user?.wallet}
                </Text>
              </div>
              <div>
                <Text size="sm" color="gray">
                  Discord Id
                </Text>
                <Text size="sm" className="break-all">
                  {selectedTicket?.user?.discordId || "-"}
                </Text>
              </div>
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
                label="Ticket Purchase Successful"
              />
              <Button loading={saving} onClick={updateTicket}>
                Save
              </Button>
            </Stack>
          </>
        )}
      </Modal>
    </div>
  );
}
export default AllPurchases;
