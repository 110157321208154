/* eslint-disable consistent-return */
import axios from "axios";
import { toast } from "react-toastify";
import getToken from "../utils/jwt-token";

export default async function api(options) {
  const { endpoint, id, responseType, ...otherOptions } = options;
  const token = getToken(options?.admin);

  const url = process.env.REACT_APP_API_URL + endpoint;
  try {
    const result = await axios({
      headers: {
        token,
        "Content-Type": "application/json",
        // eslint-disable-next-line quote-props
        Accept: "application/json",
      },
      responseType,
      url,
      ...otherOptions,
    });

    return result;
  } catch (error) {
    const customError = error;
    customError.status = error.response ? error.response.status : null;

    toast.error(`${customError.status || 500}: ${error}`);
  }
}

api.get = (options) => api({ ...options, method: "GET" });

api.post = (options) => api({ ...options, method: "POST" });

api.put = (options) => api({ ...options, method: "PUT" });

api.patch = (options) => api({ ...options, method: "PATCH" });

api.delete = (options) => api({ ...options, method: "DELETE" });
