/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useDateCountdownStyles = createStyles((theme) => ({
  group: {},
  value: {
    fontSize: 45,
    fontWeight: 800,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      // Type safe child reference in nested selectors via ref
      fontSize: 30,
    },
  },
  label: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
  },
  compactGroup: {
    display: "flex",
    gap: "4px",
  },
  compactValue: {
    fontSize: theme.fontSizes.md,
  },
}));
