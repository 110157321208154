import { Text, Title } from "@mantine/core";
import React from "react";
import getShortAddress from "../../../utils/shortAddress";

export default function Winner({ data, expired }) {
  if ((!data?.winners?.length && !data.winningBid) || !expired) return <></>;
  if (data.type === "raffle")
    return (
      <>
        {expired && data.winners.length > 0 && (
          <div>
            <Title order={3}>Winning Wallets</Title>
            {data.winners.map((winner, idx) => {
              return (
                <Text size="sm" key={idx} className="break-all">
                  {winner.wallet}
                </Text>
              );
            })}
          </div>
        )}
      </>
    );
}
