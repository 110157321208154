/* eslint-disable import/prefer-default-export */
import { createStyles } from "@mantine/core";

export const useHomeStyles = createStyles(() => ({
  wrapper: {},
  raffleGrid: {
    margin: "20px",
  },
  title: {
    alignSelf: "center",
    fontSize: "50px",
    fontWeight: "bold",
    textAlign: "center",
  },
}));
