import React, { useCallback, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Card as MantineCard,
  Image,
  Text,
  Divider,
  Group,
  Badge,
  Title,
  Container,
  Space,
} from "@mantine/core";
import { RichText } from "prismic-reactjs";
import DateCountdown from "../DateCountdown/DateCountdown";
import { useFeaturedCardStyles } from "./style";
import useWindowSize from "../../hooks/useWindowSize";
import Socials from "./components/Socials";
import BadgeSummary from "./components/BadgeSummary";
import Winner from "./components/Winner";
import DateCountdownCompact from "../DateCountdown/DateCountdownCompact";
import PurchaseActions from "./components/PurchaseActions";

function FeaturedCard({ data }) {
  const { classes } = useFeaturedCardStyles();
  const { mobile } = useWindowSize();
  const wallet = useWallet();
  const [summary, setSummary] = useState([]);

  const [expired, setExpired] = useState(
    new Date().getTime() - new Date(data.endDate).getTime() > 0
  );
  const [started, setStarted] = useState(
    new Date().getTime() - new Date(data.startDate).getTime() > 0
  );

  useEffect(() => {
    const checkTime = () => {
      setExpired(new Date().getTime() - new Date(data.endDate).getTime() > 0);
      setStarted(new Date().getTime() - new Date(data.startDate).getTime() > 0);
    };
    const timerID = setInterval(() => checkTime(), 1000);

    return () => clearInterval(timerID);
  });

  const getSummary = useCallback(async () => {
    try {
      setSummary([
        {
          label: "# Tickets",
          value: data.entries,
        },
        {
          label: "# Wallets",
          value: data.uniqueWallets,
        },
        {
          label: "# of Winners",
          value: data.data.number_of_winners,
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  if (!data) return <></>;
  const cardContent = (
    <>
      <MantineCard.Section className="relative">
        <Badge className="absolute z-10 top-5 left-5 " variant="filled">
          {data.type === "raffle" ? "Raffle" : ""}
        </Badge>
        <Image
          src={data?.data?.image?.url}
          alt="NFT Graphic"
          className={classes.image}
          radius="lg"
        />
      </MantineCard.Section>
      {!mobile && (
        <Divider
          className="my-auto"
          sx={{ height: "500px" }}
          orientation="vertical"
        />
      )}
      <div className="absolute top-2 right-2">
        {data?.data && (
          <Socials twitter={data.data.twitter} discord={data.data.discord} />
        )}
      </div>
      <div className={classes.infoWrapper}>
        <Space h="xs" />
        <Group position="apart">
          <Title order={1} className="text-text-secondary">
            {data.title}
          </Title>
        </Group>

        <Space h="sm" />

        <BadgeSummary items={summary} />

        <Divider className={classes.horizontalDivider} />
        <Text size="md">
          <RichText render={data.data.description} />
        </Text>
        <Space h="sm" />
        {data.type === "raffle" && (
          <Title order={2}>{!started ? "Starts In" : ""}</Title>
        )}
        <Group grow direction="column">
          {data.type === "raffle" ? (
            <DateCountdown
              datetime={!started ? data.startDate : data.endDate}
            />
          ) : (
            <DateCountdownCompact
              datetime={!started ? data.startDate : data.endDate}
              started={started}
            />
          )}
        </Group>

        <Space h="lg" />
        {!expired && started && (
          <PurchaseActions
            data={data}
            expired={expired}
            soldOut={data.soldOut}
            started={started}
          />
        )}

        {data.soldOut && !expired && (
          <Text className="my-1">This raffle is sold out!</Text>
        )}
        <Winner data={data} expired={expired} />
        {!wallet.connected ||
          (!wallet.publicKey && (
            <Text classes={classes.connectWallet}>
              Connect your wallet to buy tickets
            </Text>
          ))}
      </div>
    </>
  );

  return (
    <>
      <Container size={1320}>
        <div className={classes.wrapper}>
          <MantineCard withBorder shadow="lg" p="sm" radius="xl">
            <Group noWrap={!mobile}>{cardContent}</Group>
          </MantineCard>
        </div>
      </Container>
    </>
  );
}

export default FeaturedCard;
