import React, { useEffect } from "react";
import { SimpleGrid, Container, Title, Tabs, Text } from "@mantine/core";
import Card from "../../components/Card/Card";
import FeaturedCard from "../../components/FeaturedCard/FeaturedCard";
import { useHomeStyles } from "./styles";
import StoreContext from "../../contexts/store/context";

function Home() {
  const { classes } = useHomeStyles();
  const { raffleCollection, featuredCard } = React.useContext(StoreContext);
  const sortedRaffles = raffleCollection?.sort((a, b) => {
    return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {sortedRaffles?.length > 0 && (
        <Container size="1400px">
          <div className={classes.wrapper}>
            {featuredCard && <FeaturedCard isActive data={featuredCard} />}
            <Title className="mt-24 text-5xl font-bold pb-2 border-b-2 border-white">
              RAFFLES
            </Title>
            <SimpleGrid
              cols={3}
              spacing="xl"
              breakpoints={[
                { maxWidth: 980, cols: 3, spacing: "md" },
                { maxWidth: 755, cols: 2, spacing: "sm" },
                { maxWidth: 600, cols: 1, spacing: "sm" },
              ]}
              className={classes.raffleGrid}
            >
              {sortedRaffles?.map((raffle, idx) => {
                return <Card key={idx} data={raffle} />;
              })}
            </SimpleGrid>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Home;
