import React, { useEffect, useState } from "react";
import { Card, Group, Text, Title } from "@mantine/core";
import useWindowSize from "../../../hooks/useWindowSize";
import api from "../../../api/api";

function Stats() {
  const [stats, setStats] = useState();
  const { mobile } = useWindowSize();

  useEffect(() => {
    const getAdmins = async () => {
      await api
        .get({
          endpoint: "/auth/stats",
        })
        .then((response) => setStats(response.data));
    };
    getAdmins();
  }, []);

  if (!stats) return <></>;

  return (
    <div className="my-3">
      <Group position="center" grow className="text-center flex h-32 lg:h-24">
        <Card className="text-center bg-logo-color1 text-white">
          <Title>{stats.users}</Title>
          <Text size={mobile ? "sm" : "lg"}>Unique Wallets</Text>
        </Card>
        <Card className="text-center bg-logo-color2 text-gray-600">
          <Title>{stats.tickets.purchases || 0}</Title>
          <Text size={mobile ? "xs" : "lg"}>Purchase Transactions</Text>
        </Card>
        <Card className="text-center bg-logo-color1 text-white flex flex-col h-full">
          <Title>{stats.tickets.tickets || 0}</Title>
          <Text size={mobile ? "sm" : "lg"}>Total Tickets</Text>
        </Card>
        <Card className="text-center bg-logo-color2 text-gray-600">
          <Title>{stats.total}</Title>
          <Text size={mobile ? "sm" : "lg"}>
            Total ${process.env.REACT_APP_TOKEN}
          </Text>
        </Card>
      </Group>
    </div>
  );
}

export default Stats;
