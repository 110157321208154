import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Group,
  Input,
  InputWrapper,
  Loader,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import AddressForm from "../../TicketPurchaseModal/AddressForm";
import StoreContext from "../../../contexts/store/context";
import api from "../../../api/api";

function UserInfo() {
  const { user, setUser } = React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [discordId, setDiscordId] = useState(user?.discordId);
  const [size, setSize] = useState(user?.size);
  const [showDiscordId, setShowDiscordId] = useState(
    user?.discordId === user?.displayName
  );

  const [address, setAddress] = useState({
    name: user?.name,
    street: user?.street,
    street2: user?.street2,
    city: user?.city,
    state: user?.state,
    zip: user?.zip,
    country: user?.country,
  });

  useEffect(() => {
    if (user) {
      setDiscordId(user.discordId);
      setShowDiscordId(user?.discordId === user?.displayName);
      setAddress({
        name: user?.address?.name,
        street: user?.address?.street,
        street2: user?.address?.street2,
        city: user?.address?.city,
        state: user?.address?.state,
        zip: user?.address?.zip,
        country: user?.address?.country,
      });
      setSize(user.size);
    }
  }, [user]);

  const saveInfo = async () => {
    setLoading(true);
    const result = await api
      .post({
        endpoint: `/users/user/${user.id}`,
        data: {
          displayName: showDiscordId ? discordId : "",
          address,
          discordId,
          size,
        },
      })
      .then((response) => response?.data);
    if (result) setUser(result);
    setLoading(false);
  };

  return (
    <Container>
      <Space h="lg" />
      <Text>
        Your contact information is required to send out prizes when you win a
        raffle. Please check details of raffles you have won to see if this
        information is required.
      </Text>
      <Text>
        We reccomend you enter your <b>Discord Id</b> so we have a way of
        contacting you.
      </Text>
      <Space h="lg" />
      <Stack>
        <Group grow>
          <InputWrapper id="discordId" required label="Discord Id">
            <Input
              id="discordId"
              name="discordId"
              value={discordId}
              onChange={(e) => setDiscordId(e.currentTarget.value)}
            />
          </InputWrapper>
          <InputWrapper
            id="showDiscordId"
            label="Show Discord Id"
            description="Check box if you want users to see your discord id on purchases"
          >
            <Checkbox
              checked={showDiscordId}
              onChange={(event) =>
                setShowDiscordId(event.currentTarget.checked)
              }
            />
          </InputWrapper>
        </Group>
        <InputWrapper
          id="size"
          required
          label="Merch Size"
          description="Example: Shirt - Small, shoes - 7 US Mens"
        >
          <Input
            id="size"
            name="size"
            value={size}
            onChange={(e) => setSize(e.currentTarget.value)}
          />
        </InputWrapper>
        <AddressForm address={address} setAddress={setAddress} />
        <Button onClick={saveInfo}>
          {loading ? <Loader /> : <span>Save</span>}
        </Button>
      </Stack>
    </Container>
  );
}

export default UserInfo;
