import React from "react";
import { ActionIcon, Group, Image, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

function Navbar() {
  const { mobile } = useWindowSize();

  return (
    <div className="md:px-16 px-5 py-5 bg-yt-purple">
      <Group noWrap={!mobile} position="apart">
        <Link to="/">
          <Image width={60} src="/yeah-tigers/logo.png" />
        </Link>

        <Group spacing="lg">
          <a
            className="bg-yt-yellow-light border-yt-brown-dark px-5 py-2 rounded-sm hover:bg-white"
            target="_blank"
            rel="noreferrer"
            href="https://www.yeahtigers.com/"
          >
            <Text className="font-medium text-yt-text text-sm">PROJECT</Text>
          </a>
          <a
            className="bg-yt-yellow-light border-yt-brown-dark px-5 py-2 rounded-sm hover:bg-white"
            target="_blank"
            rel="noreferrer"
            href="https://www.yeahtigers.com/mindmap"
          >
            <Text className="font-medium text-yt-text text-sm">MINDMAP</Text>
          </a>
          <a
            className="bg-yt-yellow-light border-yt-brown-dark px-5 py-2 rounded-sm hover:bg-white"
            target="_blank"
            rel="noreferrer"
            href="https://www.yeahtigers.com/jungle"
          >
            <Text className="font-medium text-yt-text text-sm">JUNGLE</Text>
          </a>

          <a
            className="bg-yt-yellow-light border-yt-brown-dark px-5 py-2 rounded-sm hover:bg-white"
            target="_blank"
            rel="noreferrer"
            href="https://staking.yeahtigers.com/"
          >
            <Text className="font-medium text-yt-text text-sm">STAKING</Text>
          </a>
          <Group spacing="xs">
            <ActionIcon
              component="a"
              href="https://twitter.com/yeahtigers"
              target="_blank"
              rel="noreferrer"
              size={35}
              className="bg-yt-yellow-light rounded-sm hover:bg-white"
            >
              <Image src="/img/twitter.png" />
            </ActionIcon>
            <ActionIcon
              component="a"
              href="https://discord.com/invite/yeahtigers"
              target="_blank"
              rel="noreferrer"
              size={35}
              className="bg-yt-yellow-light rounded-sm hover:bg-white"
            >
              <Image src="/img/discord.png" />
            </ActionIcon>
          </Group>
        </Group>
      </Group>
    </div>
  );
}

export default Navbar;
