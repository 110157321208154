import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Container,
  Divider,
  Space,
  Text,
  Title,
  Grid,
  Group,
  Loader,
} from "@mantine/core";
import { useWallet } from "@solana/wallet-adapter-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import StoreContext from "../../contexts/store/context";
import api from "../../api/api";
import getShortAddress from "../../utils/shortAddress";
import useWindowSize from "../../hooks/useWindowSize";
import { getDirections } from "../../constants/constants";
import Paginate from "../Paginate/Paginate";

function RaffleData({ raffleData }) {
  const wallet = useWallet();
  const { rafflePurchases, userRafflePurchases, getRaffleDetails } =
    React.useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const id = raffleData._id;
  const totalDabs = rafflePurchases.length;
  const directions = getDirections(totalDabs);
  const { mobile } = useWindowSize();
  const [pageNumber, setPageNumber] = useState(1);

  const cols = mobile ? 2 : 12;
  const getData = useCallback(
    async (skip) => {
      if (!id) return;
      setLoading(true);
      await getRaffleDetails(skip || 0, id);
      setLoading(false);
    },
    [id, getRaffleDetails]
  );

  useEffect(() => {
    getData();
  }, [id, getData]);

  const getGridItem = (rafflePurchase) => {
    return (
      <div key={rafflePurchase._id}>
        <Divider />
        <Space h="md" />
        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Claim Id
            </Text>
            <Text size="sm">{rafflePurchase.id}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Ticket(s)
            </Text>
            <Text size="sm">{rafflePurchase.tickets}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Total ${process.env.REACT_APP_TOKEN}
            </Text>
            <Text size="sm">
              {rafflePurchase.tickets * parseInt(raffleData.ticketCost, 10)}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Purchase TXN
            </Text>
            <Text size="sm">
              {!rafflePurchase.airvault ? (
                <a
                  href={`https://solscan.io/tx/${rafflePurchase.ticketTransactionId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getShortAddress(rafflePurchase.ticketTransactionId)}{" "}
                  <FontAwesomeIcon icon="link" />
                </a>
              ) : (
                `$AIR Vault ${rafflePurchase.ticketTransactionId}`
              )}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Purchase Date
            </Text>
            <Text size="sm">
              {new dayjs(rafflePurchase.createdAt).format("MM/DD h:mm a")}
            </Text>
          </Grid.Col>
        </Grid>
        <Space h="md" />
      </div>
    );
  };

  const getAllItem = (rafflePurchase) => {
    return (
      <div key={rafflePurchase._id}>
        <Divider />
        <Space h="md" />
        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Wallet
            </Text>
            <Text size="sm">
              {rafflePurchase?.user?.wallet
                ? getShortAddress(rafflePurchase.user.wallet)
                : "-"}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Ticket(s)
            </Text>
            <Text size="sm">{rafflePurchase.tickets}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Total ${process.env.REACT_APP_TOKEN}
            </Text>
            <Text size="sm">
              {rafflePurchase.tickets * parseInt(raffleData.ticketCost, 10)}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Purchase TXN
            </Text>
            <Text size="sm">
              {!rafflePurchase.airvault ? (
                <a
                  href={`https://solscan.io/tx/${rafflePurchase.ticketTransactionId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getShortAddress(rafflePurchase.ticketTransactionId)}{" "}
                  <FontAwesomeIcon icon="link" />
                </a>
              ) : (
                `$AIR Vault ${rafflePurchase.ticketTransactionId}`
              )}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Purchase Date
            </Text>
            <Text size="sm">
              {new dayjs(rafflePurchase.createdAt).format("MM/DD h:mm a")}
            </Text>
          </Grid.Col>
        </Grid>
        <Space h="md" />
      </div>
    );
  };

  if (
    (loading || !raffleData || !rafflePurchases?.totalTickets) &&
    rafflePurchases.length > 0
  )
    return <Loader style={{ marginLeft: "50%" }} />;

  return (
    <Container size={1100}>
      <Card shadow="sm" p="xl">
        <Space h="xl" />
        {wallet.connected && (
          <Card.Section>
            <Container>
              <Group position="apart" align="end">
                <Title>
                  Your Entries ({userRafflePurchases.totalTickets || 0})
                </Title>
                <Title order={4}>
                  Total ${process.env.REACT_APP_TOKEN}{" "}
                  {(userRafflePurchases.totalTickets || 0) *
                    parseInt(raffleData.ticketCost, 10)}
                </Title>
              </Group>
              <Space h="md" />
              <Divider />
            </Container>

            <Container px={20}>
              {userRafflePurchases?.tickets?.map((rafflePurchase) => {
                return getGridItem(rafflePurchase);
              })}
            </Container>
          </Card.Section>
        )}

        <Space h="lg" />

        {rafflePurchases && (
          <Card.Section>
            <Container>
              <Group position="apart" align="end">
                <Title>All Entries ({rafflePurchases.totalTickets || 0})</Title>
                <Title order={4}>
                  Total ${process.env.REACT_APP_TOKEN}{" "}
                  {(rafflePurchases.totalTickets || 0) *
                    parseInt(raffleData.ticketCost, 10)}
                </Title>
              </Group>
              <Divider />
            </Container>

            <Container px={20}>
              {rafflePurchases?.tickets?.map((rafflePurchase) => {
                return getAllItem(rafflePurchase);
              })}
            </Container>
            <Space h="lg" />
            <Paginate
              callBack={(skip) => getData(skip)}
              total={totalDabs}
              directions={directions}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
            />
          </Card.Section>
        )}
        <Space h="xl" />
      </Card>
    </Container>
  );
}

export default RaffleData;
