// eslint-disable-next-line import/prefer-default-export
const config = {
  appTitle: "Shrouded Playground",
  subtitle: "Raffles",
  links: {
    twitter: "https://twitter.com/yeahtigers",
    discord: "https://discord.com/invite/yeahtigers",
  },
  colors: {
    "text-primary": "#ffffff",
    "text-secondary": "#C1C2C5",
    "header-text": "rgb(194 163 80 / 1)",
    "text-accent": "rgb(255 201 56/1)",
    "button-text": "#96f2d7",
  },
  getGlobalTheme: () => {
    return {
      colorScheme: "light",
      fontFamily: "Montserrat, sans-serif",
      headings: { fontFamily: "CheddarGothicSans", fontWeight: 900 },
      primaryColor: "orange",
      fontSizes: {
        xl: 30,
      },
    };
  },
};

export default config;
