import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Anchor,
  Container,
  Divider,
  Grid,
  Space,
  Text,
} from "@mantine/core";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import api from "../../../api/api";
import SkeletonGrid from "../../../components/SkeletonGrid";
import useWindowSize from "../../../hooks/useWindowSize";
import getShortAddress from "../../../utils/shortAddress";

function Winners() {
  const { mobile } = useWindowSize();
  const [raffles, setRaffles] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    const getAllRaffles = async () => {
      setLoading(true);
      await api
        .get({
          endpoint: "/auth/winners",
        })
        .then((response) => setRaffles(response?.data));
      setLoading(false);
    };
    getAllRaffles();
  }, []);

  const cols = mobile ? 2 : 12;

  const getGridItem = (raffle) => {
    return (
      <div key={raffle._id}>
        <Space h="md" />

        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="xs" color="gray">
              Raffle
            </Text>
            <Text size="sm">{raffle.title}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Ticket(s) Sold
            </Text>
            <Text size="sm">{raffle.entries}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Total ${process.env.REACT_APP_TOKEN}
            </Text>
            <Text size="sm">{raffle.entries * raffle.ticketCost}</Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              End Date
            </Text>
            <Text size="sm">
              {new dayjs(raffle.endDate).format("MM/DD h:mm a")}
            </Text>
          </Grid.Col>
          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="xs" color="gray">
              Winners Announced
            </Text>
            {raffle.winners.length > 0 && (
              <FontAwesomeIcon icon="check-circle" color="green" />
            )}
            {raffle.winners.length === 0 && (
              <FontAwesomeIcon icon="times" color="red" />
            )}
          </Grid.Col>
        </Grid>
        <Space h="md" />
      </div>
    );
  };

  const getWinner = (winner) => {
    return (
      <div key={winner.id}>
        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="sm" color="gray">
              User Id
            </Text>
            <Text size="ms">{winner.id}</Text>
          </Grid.Col>
          <Grid.Col span={mobile ? 1 : 4}>
            <Text size="sm" color="gray">
              Wallet
            </Text>
            <Text size="sm" className="break-all">
              {winner.wallet}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="sm" color="gray">
              Discord Id
            </Text>
            <Text size="ms">{winner.discordId}</Text>
          </Grid.Col>
        </Grid>
      </div>
    );
  };

  const getTicket = (winningTicket) => {
    return (
      <div key={winningTicket._id}>
        <Grid columns={cols} gutter="sm">
          <Grid.Col span={mobile ? 1 : 3}>
            <Text size="sm" color="gray">
              Ticket Id
            </Text>
            <Text size="ms">{winningTicket._id}</Text>
          </Grid.Col>
          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="sm" color="gray">
              # Tickets
            </Text>
            <Text size="sm" className="break-all">
              {winningTicket.tickets}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="sm" color="gray">
              Purchase TXN
            </Text>
            {!winningTicket.airvault ? (
              <Anchor
                target="_blank"
                href={`https://solscan.io/tx/${winningTicket.ticketTransactionId}`}
              >
                <Text size="ms">
                  {getShortAddress(winningTicket.ticketTransactionId)}
                </Text>
              </Anchor>
            ) : (
              `$AIR Vault ${winningTicket.ticketTransactionId}`
            )}
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="sm" color="gray">
              Purchase Successful
            </Text>
            <Text size="md">
              {winningTicket.ticketPurchaseSuccessful && (
                <FontAwesomeIcon icon="check-circle" color="green" />
              )}
              {!winningTicket.ticketPurchaseSuccessful && (
                <FontAwesomeIcon icon="times" color="red" />
              )}
            </Text>
          </Grid.Col>

          <Grid.Col span={mobile ? 1 : 2}>
            <Text size="sm" color="gray">
              Date
            </Text>
            <Text size="ms">
              {dayjs(winningTicket.createdAt).format("DD/MM/YY hh:mm a")}
            </Text>
          </Grid.Col>
        </Grid>
      </div>
    );
  };

  return (
    <Container size={1500} px={20}>
      {loading && <SkeletonGrid amount={5} height="106px" />}
      <Accordion initialItem={0}>
        {raffles?.map((raffle) => {
          return (
            <Accordion.Item key={raffle._id} label={getGridItem(raffle)}>
              {raffle.winningTickets.map((ticket, i) => {
                const odd = i % 2;
                return (
                  <div
                    className={
                      odd ? "px-3 bg-gray-light" : "px-3 bg-gray-semi-light"
                    }
                  >
                    <Space h="xs" />
                    {getWinner(ticket.user, raffle.ticketCost)}
                    <Space h="xs" />
                    <Divider />
                    <Space h="xs" />
                    {getTicket(ticket)}
                    <Space h="xs" />
                  </div>
                );
              })}
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}

export default Winners;
