import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Group, Text } from "@mantine/core";
import React from "react";

function Paginate({ total, directions, callBack, pageNumber, setPageNumber }) {
  const paginate = (direction) => {
    let skip = (pageNumber - 1) * 30 + direction * 30;
    if (direction === directions.first) skip = 0;
    if (direction === directions.last) skip = (Math.ceil(total / 30) - 1) * 30;
    if (
      (direction === directions.first && pageNumber === 1) ||
      (direction === directions.next && pageNumber === Math.ceil(total / 30)) ||
      (direction === directions.back && pageNumber === 1) ||
      (direction === directions.last && pageNumber === Math.ceil(total / 30))
    ) {
      return;
    }
    let newPageNumber = pageNumber + direction;

    if (direction === directions.first) newPageNumber = 1;
    if (direction === directions.last) newPageNumber = Math.ceil(total / 30);
    setPageNumber(newPageNumber);
    callBack(skip);
    window.scrollTo(0, 0);
  };

  return (
    <Container>
      <Group position="right">
        <Button
          disabled={pageNumber === 1}
          onClick={() => paginate(directions.first)}
        >
          <FontAwesomeIcon icon="angle-double-left" />
        </Button>
        <Button
          disabled={pageNumber === 1}
          onClick={() => paginate(directions.back)}
        >
          <FontAwesomeIcon icon="angle-left" />
        </Button>
        <Text>{pageNumber}</Text>
        <Button
          disabled={pageNumber === Math.ceil(total / 30)}
          onClick={() => paginate(directions.next)}
        >
          <FontAwesomeIcon icon="angle-right" />
        </Button>
        <Button
          disabled={pageNumber === Math.ceil(total / 30)}
          onClick={() => paginate(directions.last)}
        >
          <FontAwesomeIcon icon="angle-double-right" />
        </Button>
      </Group>
    </Container>
  );
}

export default Paginate;
