/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useMemo, useEffect, useState } from "react";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";
import * as anchor from "@project-serum/anchor";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faLink,
  faTimes,
  faWallet,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, MantineProvider, useMantineTheme } from "@mantine/core";
import { Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RichText } from "prismic-reactjs";
import config from "../../config/index";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import StoreProvider from "../../contexts/store/provider";
import Home from "../Home/Home";
import Winners from "../Winners/Winners";
import Raffle from "../Raffle/Raffle";
import api from "../../api/api";
import Subheader from "../../components/Subheader/Subheader";
import Admin from "../Admin/Admin";

const network = process.env.REACT_APP_SOLANA_NETWORK;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(
  rpcHost || anchor.web3.clusterApiUrl("devnet")
);

library.add(
  fab,
  faWallet,
  faCheckCircle,
  faTimes,
  faLink,
  faInfoCircle,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faExclamationCircle,
  faCopy
);

function App() {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const [alert, setAlert] = useState();
  const { getGlobalTheme } = config;

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  const theme = useMantineTheme();
  const globalMantineTheme = getGlobalTheme(theme);

  useEffect(() => {
    const getAlert = async () => {
      await api
        .get({
          endpoint: "/prismic/alert-banner",
        })
        .then((response) => setAlert(response?.data));
    };
    getAlert();
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletDialogProvider>
          <MantineProvider theme={globalMantineTheme}>
            <StoreProvider connection={connection}>
              <div className="bg-main bg-height bg-no-repeat md:bg-width bg-top">
                {alert && alert?.data?.show_banner && (
                  <Alert
                    icon={<FontAwesomeIcon icon="exclamation-circle" />}
                    title={alert.data.title}
                    color="red"
                  >
                    <RichText render={alert?.data?.body} />
                  </Alert>
                )}
                <Header />
                <div className="min-h-screen flex flex-col">
                  <Subheader connection={connection} />

                  <Routes>
                    <Route
                      path="/"
                      element={<Home connection={connection} />}
                    />
                    <Route
                      path="/raffle/:id"
                      element={<Raffle connection={connection} />}
                    />
                    <Route
                      path="/winners/:id"
                      element={<Winners connection={connection} />}
                    />
                    <Route path="/admin" element={<Admin />} />
                  </Routes>
                  <Footer />
                </div>
              </div>
            </StoreProvider>
          </MantineProvider>
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
