import React from "react";
import {
  WalletDialogButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import { Button, Group, Modal, Space, Text, Title } from "@mantine/core";
import StoreContext from "../../contexts/store/context";

const tokenModal = {
  title: `Not Enough $${process.env.REACT_APP_TOKEN}`,
  description: `You do not have enought $${process.env.REACT_APP_TOKEN} to purchase a raffle ticket.`,
  buttons: {
    cancel: {
      title: "Close",
      link: false,
    },
    ok: {
      title: `BUY $${process.env.REACT_APP_TOKEN}`,
      link: true,
      href: "https://jup.ag/swap",
    },
  },
  opened: true,
};
const solModal = {
  title: "Not Enough SOL",
  description: `To purchase a $${process.env.REACT_APP_TOKEN} Island Raffle ticket we reccomend you have at least 0.01 SOL.`,
  buttons: {
    cancel: {
      title: "Close",
      link: false,
    },
    ok: {
      title: "BUY SOL",
      link: true,
      href: "https://coincodex.com/article/9442/how-to-buy-solana-on-FTX/",
    },
  },
  opened: true,
};

const defaultModal = {
  title: "",
  description: "",
  buttons: {
    cancel: {
      title: "Close",
      link: false,
    },
    ok: {
      title: "",
      link: false,
      onClick: () => {},
    },
  },
  opened: false,
};

const modalContent = {
  token: tokenModal,
  sol: solModal,
};

function Wallet() {
  const { wallet, fundsModal, setFundsModal } = React.useContext(StoreContext);
  const modal = modalContent[fundsModal] || defaultModal;

  return (
    <div>
      {modal && (
        <Modal
          opened={modal.opened}
          onClose={() => setFundsModal(undefined)}
          centered
          title={
            <Title order={1} transform="uppercase">
              {modal.title}
            </Title>
          }
        >
          <Text>{modal.description}</Text>

          <Space h="md" />
          <Group position="center">
            <Button
              style={{ flexGrow: 1 }}
              component="a"
              href={modal?.buttons?.ok?.href}
              target="_blank"
            >
              {modal?.buttons?.ok?.title}
            </Button>

            <Button
              onClick={() => setFundsModal(undefined)}
              style={{ flexGrow: 1 }}
              variant="outline"
            >
              Close
            </Button>
          </Group>
        </Modal>
      )}
      {!wallet.connected && (
        <WalletDialogButton>Connect Wallet</WalletDialogButton>
      )}
      {wallet.connected && (
        <WalletDisconnectButton>Disconnect Wallet</WalletDisconnectButton>
      )}
    </div>
  );
}

export default Wallet;
