import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Group,
  Image,
  Input,
  InputWrapper,
  Modal,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import StoreContext from "../../contexts/store/context";
import getShortAddress from "../../utils/shortAddress";
import { useModalStyles } from "./styles";
import AddressForm from "./AddressForm";
import api from "../../api/api";

const addressValid = (address) => {
  return (
    address.name?.trim() &&
    address.street?.trim() &&
    address.city?.trim() &&
    address.state?.trim() &&
    address.zip?.trim() &&
    address.country?.trim()
  );
};

function TicketPurchaseModal({ opened, setIsOpen }) {
  const {
    modal: {
      ticketPurchaseSuccessful,
      numberOfTicketsPurchased,
      raffle,
      raffleName,
      txnID,
      title,
      airvault,
    },
    user,
    setUser,
  } = React.useContext(StoreContext);
  const [discordId, setDiscordId] = useState(user?.discordId);
  const [size, setSize] = useState(user?.size);
  const [address, setAddress] = useState({
    name: user?.name,
    street: user?.street,
    street2: user?.street2,
    city: user?.city,
    state: user?.state,
    zip: user?.zip,
    country: user?.country,
  });
  const [step, setStep] = useState(1);
  const inputRequired =
    raffle?.data?.require_address ||
    raffle?.data?.require_discord_id ||
    raffle?.data?.require_address;
  const numberSteps = raffle?.data?.require_address ? 2 : 1;
  const nextDisabled =
    (raffle?.data?.require_discord_id && !discordId?.trim()) ||
    (raffle?.data?.require_merch_size && !size?.trim());
  const confirmDisabled =
    raffle?.data?.require_address && !addressValid(address);

  const { classes } = useModalStyles();
  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    if (user) {
      setDiscordId(user.discordId);
      setAddress({
        name: user?.address?.name,
        street: user?.address?.street,
        street2: user?.address?.street2,
        city: user?.address?.city,
        state: user?.address?.state,
        zip: user?.address?.zip,
        country: user?.address?.country,
      });
      setSize(user.size);
    }
  }, [user]);

  if (!raffle) return <></>;
  const successfulBody = (
    <div>
      <Text size="sm">
        You’ve successfully purchased {numberOfTicketsPurchased} amount of
        raffle ticket(s) for the <b>&quot;{raffleName}&quot;</b> raffle, thanks
        for participating!
      </Text>
      <Space h="md" />
      <Text size="sm">
        Make sure to come back at{" "}
        {new dayjs(raffle.endDate).format("MM/DD h:mm a")} to see if you’re a
        winner!
      </Text>
      <Space h="md" />
      <Text size="sm">
        <Text size="xs" color="gray" weight="bold">
          Raffle Ticket TXIN:
        </Text>
        <a
          href={`https://solscan.io/tx/${txnID}`}
          target="_blank"
          rel="noreferrer"
        >
          {getShortAddress(txnID)}
          <FontAwesomeIcon icon="link" />
        </a>
      </Text>
    </div>
  );

  const unconfirmedPurchase = (
    <div>
      <Text size="sm">
        You have attempted to purchase {numberOfTicketsPurchased} raffle
        ticket(s) for the <b>&quot;{raffleName}&quot;</b> raffle, thanks for
        participating!
      </Text>
      <Space h="md" />
      <Text size="sm">
        It looks like your ticket purchase transaction may not have gone
        through. Use the link below to check your Transaction ID on Solscan.
      </Text>
      <Text size="sm">
        If it failed, you will <b>need to repurchase your tickets</b>. If it did
        go through, please wait 10-20 minutes for the scheduled job to
        re-confirm your purchase. If solscan is showing your transaction is
        valid and it has been more than 30 minutes please contact the team via
        discord.
      </Text>
      <Space h="md" />
      <Text size="sm">
        <Text size="xs" color="gray" weight="bold">
          Raffle Ticket TXIN:
        </Text>
        {airvault ? (
          <div>$AIR vault: {txnID}</div>
        ) : (
          <a
            href={`https://solscan.io/tx/${txnID}`}
            target="_blank"
            rel="noreferrer"
          >
            {getShortAddress(txnID)}
            <FontAwesomeIcon icon="link" />
          </a>
        )}
      </Text>
    </div>
  );

  const handleClicked = async () => {
    if (step === numberSteps) {
      closeModal();
      if (inputRequired) {
        const result = await api
          .post({
            endpoint: `/users/user/${user.id}`,
            data: {
              address,
              discordId,
              size,
              name: address.name,
            },
          })
          .then((response) => response.data);
        setUser(result);
      }
    } else setStep(step + 1);
  };

  const bodyText = ticketPurchaseSuccessful
    ? successfulBody
    : unconfirmedPurchase;

  const modalBody = (
    <Group align="flex-start">
      <Image src={raffle.data.image.url} width="350px" height="350px" />
      <Container>
        <Group
          style={{ minHeight: "350px" }}
          direction="column"
          position="apart"
        >
          <div style={{ maxWidth: "400px" }}>
            {bodyText}
            <Space h="md" />
            {raffle.data.require_discord_id && (
              <InputWrapper id="discordId" required label="Discord Id">
                <Input
                  id="discordId"
                  name="discordId"
                  value={discordId}
                  onChange={(e) => setDiscordId(e.currentTarget.value)}
                />
              </InputWrapper>
            )}
            <Space h="sm" />
            {raffle.data.require_merch_size && (
              <InputWrapper id="size" required label="Merch Size">
                <Input
                  id="size"
                  name="size"
                  value={size}
                  onChange={(e) => setSize(e.currentTarget.value)}
                />
              </InputWrapper>
            )}
          </div>
          <Button
            style={{ width: "100%", marginTop: "auto" }}
            onClick={handleClicked}
            disabled={nextDisabled}
          >
            {numberSteps > 1 && step === 1 ? "Next" : "OK"}
          </Button>
        </Group>
      </Container>
    </Group>
  );

  return (
    <Modal
      closeOnClickOutside={false}
      withCloseButton={false}
      opened={opened}
      size={850}
      className={classes.modal}
      title={
        <Title order={1} transform="uppercase">
          {title}
        </Title>
      }
    >
      <div className={classes.content}>
        <Space h="md" />
        {step === 1 && modalBody}
        {step === 2 && (
          <AddressForm address={address} setAddress={setAddress} />
        )}
        <Space h="md" />
        <Group direction="row">
          {step > 1 && (
            <Button
              style={{ width: "49%", marginTop: "auto" }}
              onClick={() => setStep(step - 1)}
              disabled={!discordId && raffle.data.require_discord_id}
              variant="outline"
            >
              Back
            </Button>
          )}
          {step > 1 && (
            <Button
              style={{ width: "49%", marginTop: "auto" }}
              onClick={handleClicked}
              disabled={confirmDisabled}
            >
              {numberSteps > 1 && step === 1 ? "Next" : "OK"}
            </Button>
          )}
        </Group>

        <Space h="md" />
      </div>
    </Modal>
  );
}

export default TicketPurchaseModal;
